import React, { useReducer, useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import logoImg from "../assets/images/logo-footer.png"

import { FiMapPin, FiMail, FiPhone, FiShoppingCart, FaDribbble, FaBehance, FaLinkedin, FaFacebookF, FaInstagram, FaTwitter, FaRegFile } from '../assets/icons/icons'

export default function Footer(){
    const [id,setId] = useState(0)
    const [company_email,setCompanyEmail] = useState('')
    const [company_logo,setCompanyLogo] = useState(null)
    const [company_address,setCompanyAddress] = useState(null)
    const [company_logo_asset,setCompanyLogoAssets] = useState(null)
    const [company_number,setCompanyNumber] = useState('')
    const [meta_title,setMetaTitle] = useState('')
    const [meta_description,setMetaDescription] = useState('')
    const [meta_keyword,setMetaKeyword] = useState('')
    const [facebook_link,setFacebookLink] = useState('')
    const [twitter_link,setTwitterLink] = useState('')
    const [instagram_link,setInstagramLink] = useState('')
    useEffect(() => {
        const fetchSetting = async () => {
            const response = await fetch('/api/setting/')
            const json = await response.json();
            if(response.ok){
                if(json){
                    setId(json._id)
                    if(json.company_logo)
                    setCompanyLogo(json.company_logo)
                    setCompanyEmail(json.company_email)
                    setCompanyNumber(json.company_number)
                    setCompanyAddress(json.company_address)
                    setFacebookLink(json.facebook_link)
                    setTwitterLink(json.twitter_link)
                    setInstagramLink(json.instagram_link)
                    setMetaDescription(json.meta_description)
                    setMetaKeyword(json.meta_keyword)
                    setMetaTitle(json.meta_title)
                    setCompanyLogo(`/images/`+json.company_logo)

                }
            }
            
        }
        fetchSetting()
    }, []);
    return(
        <footer className="bg-footer footer-bar">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 mb-0 mb-md-4 pb-0 pb-md-2">
                        <Link to="#" className="logo-footer">
                            <img src={company_logo} height="48" alt=""/>
                        </Link>
                        <ul className="list-unstyled footer-list px-0 mt-4">
                            <li><FiMapPin className="fea icon-sm me-2"/><Link to="#" className="text-foot">{company_address}</Link></li>
                            <li><FiMail className="fea icon-sm me-2"/><Link to="mailto:contact@example.com" className="text-foot">{company_email}</Link></li>
                            <li><FiPhone className="fea icon-sm me-2"/><Link to="tel:+152534-468-854" className="text-foot">{company_number}</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-9 col-md-12">
                        <div className="row">
                            <div className="col-md-3 col-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">Company</h5>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><Link to="3" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> About us</Link></li>
                                    <li><Link to="3" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Services</Link></li>
                                    <li><Link to="3" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Team</Link></li>
                                    <li><Link to="3" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Project</Link></li>
                                    <li><Link to="3" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Blog</Link></li>
                                    <li><Link to="3" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Login</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="pt-4 footer-bar">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="text-sm-start text-center">
                                <ul className="list-unstyled social-icon social mb-0 mt-4 mt-sm-0">
                                    <li className="list-inline-item"><Link to={facebook_link} target="_blank" className="rounded"><FaFacebookF className="align-middle"/></Link></li>
                                    <li className="list-inline-item"><Link to={instagram_link} target="_blank" className="rounded"><FaInstagram className="align-middle"/></Link></li>
                                    <li className="list-inline-item"><Link to={twitter_link} target="_blank" className="rounded"><FaTwitter className="align-middle"/></Link></li>
                                </ul>
                            </div>
                        </div>
    
                       
                    </div>

                    <div className="row justify-content-center mt-4">
                        <div className="col-12 text-center">
                            <p className="mb-0">© {new Date().getFullYear()} Powered <i className="mdi mdi-heart text-danger"></i> by <Link to="https://shreethemes.in" target="_blank" className="text-reset">Operation Track</Link>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}