import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import GridPortfolio from "../../components/gridPortfolio";

export default function GridFive(){
    return(
        <>
        <Navbar/>
        <section className="bg-half-170 d-table w-100 bg-light">
        <div className="container">
            <div className="row mt-5 justify-content-center">
                <div className="col-12">
                    <div className="heading-title text-center">
                        <h1 className="title fw-bold">Works & Project</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="position-relative">
        <div className="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>

    <section className="section">
       <GridPortfolio container="container-fluid" col="col-lg-12" grid="row row-cols-lg-5 row-cols-md-2"/>
    </section>
    <Footer/>
    <ScrollTop/>
        </>
    )
}