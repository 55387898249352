import React from "react";
import { Link } from "react-router-dom";

import { FaApple, RiGooglePlayLine, FiSmartphone } from '../assets/icons/icons'


export default function FooterTop(){
    return(
        <div className="container-fluid px-0">
            <div className="py-5 bg-footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="section-title">
                                <div className="d-flex">
                                    <FiSmartphone className="display-4 text-white title-dark"/>
                                    <div className="flex-1 ms-md-4 ms-3">
                                        <h4 className="fw-bold text-light title-dark mb-1">Download our app now !</h4>
                                        <p className="text-white-50 mb-0">Build modern looking websites fast and easy using Landflow.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5 mt-4 mt-sm-0">
                            <div className="text-md-end ms-5 ms-sm-0">
                                <Link to="#" className="btn btn-primary me-2 me-lg-2 me-md-0 my-2"><FaApple /> App Store</Link>
                                <Link to="#" className="btn btn-soft-primary my-2"><RiGooglePlayLine /> Play Store</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}