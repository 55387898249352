import React,{useState} from "react";
import { Link } from "react-router-dom";

import { portfolio } from "../data/data";

export default function GridPortfolio({container,grid,col}){
    const [selectedCategory, setSelectedCategory] = useState(null);


    const matchCategory = (category) => {
        setSelectedCategory(category);
    };

    const filteredData = selectedCategory
        ? portfolio.filter((item) => item.category === selectedCategory)
        : portfolio;
    return(
        <>
         <div className="container mb-4">
            <div className="row justify-content-center">
                <div className="col-12 filters-group-wrap">
                    <div className="filters-group">
                        <ul className="container-filter categories-filter list-inline mb-0 filter-options text-center">
                            <li className={`${selectedCategory === null ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory(null)}>All</li>
                            <li className={`${selectedCategory === 'branding' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('branding')}>Branding</li>
                            <li className={`${selectedCategory === 'designing' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('designing')}>Designing</li>
                            <li className={`${selectedCategory === 'photography' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('photography')}>Photography</li>
                            <li className={`${selectedCategory === 'development' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('development')}>Development</li>
                        </ul>
                    </div>
                </div>    
            </div>
        </div>

        <div className={container}>
            <div className="row justify-content-center">
                <div className={col}>
                    <div id="grid" className={grid}>
                        {filteredData.map((item, index) =>{
                            return(
                            <div className="col-12 spacing picture-item" data-groups='["photography"]' key={index}>
                                <div className="card portfolio portfolio-grid d-grid border-0">
                                    <div className="portfolio-box position-relative rounded-2 shadow overflow-hidden">
                                        <img src={item.image} className="img-fluid port-img" alt=""/>
                                        <div className="overlay-work"></div>
                                        <div className="content bottom-0 p-4 position-absolute">
                                            <h6 className="tag mb-1">{item.name}</h6>
                                            <Link to="" className="title fw-bold d-block mb-0 text-decoration-none h5">{item.title}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                    <div className="row mt-4 pt-2">
                        <div className="col-12">
                            <ul className="pagination justify-content-center mb-0">
                                <li className="page-item ms-0"><Link className="page-link" to="#" aria-label="Previous">Prev</Link></li>
                                <li className="page-item ms-0 active"><Link className="page-link" to="#">1</Link></li>
                                <li className="page-item ms-0"><Link className="page-link" to="#">2</Link></li>
                                <li className="page-item ms-0"><Link className="page-link" to="#">3</Link></li>
                                <li className="page-item ms-0"><Link className="page-link" to="#" aria-label="Next">Next</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}