import {React, useState,useEffect } from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-faqs.jpg'

import Navbar from "../../components/navbar";
import { accordionData, faqAbout } from "../../data/data";

import { FiPhone, FiMail } from '../../assets/icons/icons'
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

export default function PageFaqs(){
    let [ activeIndex, setActiveIndex ] = useState(1);
    const [faqs, setFaqs] = useState([]);
    useEffect(() => {
        const fetchFaqs = async () => {
            const response = await fetch('/api/faqs/list');
            const json = await response.json();
            if(response.ok){
                setFaqs(json)
                console.log('json',json);
            }
            
        }
        fetchFaqs()
    }, []);
    return(
        <>
        <Navbar navLight={true}/>

        <section className="bg-half-260 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="bg-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="heading-title text-center">
                            <h2 className="title text-white title-dark">How can we help you ?</h2>
                            <div className="subcribe-form my-4">
                                <form>
                                    <div className="mb-0">
                                        <input type="text" id="name" name="name" className="border bg-white rounded-pill" style={{opacity: '0.8'}} required placeholder="Ask a questions..."/>
                                        <button type="submit" className="btn btn-pills btn-primary">Search</button>
                                    </div>
                                </form>
                            </div>
                            <p className="text-white-50 mb-0 para-desc mx-auto">*We are collect your searching keywords to improve our FAQ</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section">
            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-4">Choose a category to quickly find</h4>
                            <p className="text-muted para-desc mx-auto mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {faqAbout.map((item, index) =>{
                        let Icon = item.icon
                        return(
                            <div className="col-lg-4 col-md-6 mt-4 pt-2" key={index}>
                                <div className="card border-0 text-center features feature-primary rounded p-4 shadow">
                                    <div className="icon text-center mx-auto rounded-md">
                                        <h3 className="mb-0"><Icon/></h3>
                                    </div>

                                    <div className="card-body p-0 mt-4">
                                        <h5>{item.title}</h5>
                                        <p className="text-muted mb-0">{item.desc}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div> */}

            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="mb-4">General Questions in Operation track</h4>
                            {/* <p className="para-desc mx-auto text-muted mb-0">.</p> */}
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-9 mt-4 pt-2">
                        <div className="accordion">
                            {faqs.map((item, index) =>{
                                return(
                                    <div className="accordion-item rounded mt-2" key={index}>
                                        <h2 className="accordion-header">
                                            <button className={`${activeIndex === item._id ? '' : 'collapsed' } accordion-button border-0 bg-light`} type="button" onClick={() =>setActiveIndex(item._id)}>
                                                {item.question}
                                            </button>
                                        </h2>
                                        <div className={`${activeIndex === item._id ? 'show' : '' } accordion-collapse border-0 collapse`}>
                                            <div className="accordion-body text-muted">
                                               <p dangerouslySetInnerHTML={{__html: item.answer}}></p> 
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="mb-4">Still have a questions ?</h4>
                            <p className="para-desc mx-auto text-muted mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="row">
                            <div className="col-md-6 mt-4 pt-2">
                                <div className="card border-0 text-center features feature-primary rounded">
                                    <div className="icon text-center mx-auto rounded-md">
                                        <h3 className="mb-0"><FiPhone /></h3>
                                    </div>
        
                                    <div className="card-body p-0 mt-4">
                                        <h5>Phone</h5>
                                        <p className="text-muted">Start working with Landflow that can provide everything</p>
                                        <Link to="tel:+152534-468-854" className="link">+152 534-468-854</Link>
                                    </div>
                                </div>
                            </div>
        
                            <div className="col-md-6 mt-4 pt-2">
                                <div className="card border-0 text-center features feature-primary rounded">
                                    <div className="icon text-center mx-auto rounded-md">
                                        <h3 className="mb-0"><FiMail /></h3>
                                    </div>
        
                                    <div className="card-body p-0 mt-4">
                                        <h5>Email</h5>
                                        <p className="text-muted">Start working with Landflow that can provide everything</p>
                                        <Link to="mailto:contact@example.com" className="link">contact@example.com</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-footer">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <Footer/>
        <ScrollTop/>
        </>
    )
}