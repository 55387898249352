import React, { useReducer, useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-contact.png'
import Navbar from "../../components/navbar";

import { FiPhone, FiMail, FiMapPin } from '../../assets/icons/icons'
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import swal from "sweetalert";	


export default function Contact(){
    const [id,setId] = useState(0)
    const [company_email,setCompanyEmail] = useState('')
    const [company_address,setCompanyAddress] = useState(null)
    const [company_number,setCompanyNumber] = useState('')

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [description, setDescription] = useState('')
     const handleSubmit = async(e) =>{
        e.preventDefault()

        const contact = {name,email,subject,description}

        const response = await fetch('/api/contact',{
            method:'POST',
            body:JSON.stringify(contact),
            headers:{
                'Content-Type':'application/json',
            }
        })

        const json = await response.json();
        if(!response.ok){
            // setError(json.error)
            swal(json.error,'','error');
        }

        if(response.ok){
            setName('');
            setEmail('');
            setSubject('');
            setDescription('');
            swal("Queries Sent Successfully",'','success');
        }
    }

    useEffect(() => {
        const fetchSetting = async () => {
            const response = await fetch('/api/setting/')
            const json = await response.json();
            if(response.ok){
               
                setId(json._id)
                setCompanyEmail(json.company_email)
                setCompanyNumber(json.company_number)
                setCompanyAddress(json.company_address)
               
               
                // console.log(json);
            }
            
        }
        fetchSetting()
    }, []);
    return(
        <>
        <Navbar navLight={true}/>

        <section className="bg-half-170 bg-primary d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="heading-title text-center">
                            <h2 className="text-white title-dark title mb-3">Contact us</h2>
                            <p className="para-desc mx-auto text-white-50">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section pb-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="features-absolute bg-white p-4 p-md-5 rounded shadow">
                            <div className="custom-form">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                                <input name="name" id="name" type="text" className="form-control" onChange={(e)=>setName(e.target.value)} value={name} placeholder="Name :"/>
                                            </div>
                                        </div>
    
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                <input name="email" id="email" type="email"  className="form-control" onChange={(e)=>setEmail(e.target.value)} value={email} placeholder="Email :"/>
                                            </div> 
                                        </div>
    
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Subject</label>
                                                <input name="subject" id="subject" className="form-control" onChange={(e)=>setSubject(e.target.value)} value={subject} placeholder="subject :"/>
                                            </div>
                                        </div>
    
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Comments <span className="text-danger">*</span></label>
                                                <textarea name="comments" id="comments" rows="4" className="form-control" onChange={(e)=>setDescription(e.target.value)} value={description} placeholder="Message :"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-grid">
                                                <button type="button" id="submit" name="send" className="btn btn-primary" onClick={handleSubmit}>Send Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="card border-0 text-center features feature-primary">
                            <div className="icon text-center mx-auto rounded-md">
                                <h3 className="mb-0"><FiPhone /></h3>
                            </div>

                            <div className="card-body p-0 mt-4">
                                <h5 className="title fw-bold">Phone</h5>
                                {/* <p className="text-muted">Start working with Landflow that can provide everything</p> */}
                                <Link to="tel:+152534-468-854" className="link">{company_number}</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="card border-0 text-center features feature-primary">
                            <div className="icon text-center mx-auto rounded-md">
                                <h3 className="mb-0"><FiMail /></h3>
                            </div>

                            <div className="card-body p-0 mt-4">
                                <h5 className="title fw-bold">Email</h5>
                                {/* <p className="text-muted">Start working with Landflow that can provide everything</p> */}
                                <Link to="mailto:contact@example.com" className="link">{company_email}</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div className="card border-0 text-center features feature-primary">
                            <div className="icon text-center mx-auto rounded-md">
                                <h3 className="mb-0"><FiMapPin /></h3>
                            </div>

                            <div className="card-body p-0 mt-4">
                                <h5 className="title fw-bold">Location</h5>
                                <p className="text-muted">{company_address}</p>
                                <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                data-type="iframe" className="lightbox video-play-icon h6 link">View on Google map</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-100 mt-60">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="card map border-0">
                            <div className="card-body p-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{border:'0'}} title="landFlow" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}