import React from "react";
import { Link } from "react-router-dom";

import saas from '../assets/images/saas/1.png'
import taskmap from '../assets/images/taskmap.png'

import Group5 from '../assets/images/Group5.png'

import { TbBolt, BsPentagon, FiArrowRight, MdOutlineCheckCircle} from '../assets/icons/icons'

export default function AnalyticsTwo(){
    return(
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="me-lg-5">
                        <div className="section-title">
                            <h6 className="text-primary">Analytics</h6>
                            <h4 className="title mb-4">Analyze your data with <br/> our analysis tools.</h4>
                            <p className="text-muted para-desc mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mt-4 pt-2">
                                <div className="card features feature-primary bg-transparent border-0">
                                    
                                    <div className="card-body p-0 mt-4">
                                        <h5>Other Features</h5>
                                        <ul className="list-unstyled text-muted mt-3">
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span>Driver chat (coming soon)</li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span>Proof of delivery</li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span>Predictive eta </li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span>Automated Status Updates</li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span>Integrated Customer Communication </li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span>Feedback collection</li>
                        </ul>
                                        
                                    </div>
                                </div>
                            </div>

                           
                        </div>

                        <div className="mt-4 pt-2">
                            <Link to="#" className="btn btn-soft-primary">Try for free <FiArrowRight className="fea icon-sm"/></Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-6" >
                    
                    <img src={Group5} className="img-fluid" alt=""/>
                
                </div>
            </div>
    )
}