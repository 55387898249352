import React from "react";
import { Link } from "react-router-dom";

import work1 from '../../assets/images/work/1.jpg'
import work2 from '../../assets/images/work/2.jpg'
import work3 from '../../assets/images/work/3.jpg'
import work4 from '../../assets/images/work/4.jpg'

import Navbar from "../../components/navbar";

import { FiTag, FiCalendar, FiArrowLeft, FiArrowRight, FiHome } from '../../assets/icons/icons'
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

export default function WorkDetail(){
    return(
        <>
        <Navbar/>
        <section className="bg-half-170 d-table w-100 pb-0">
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="heading-title">
                            <ul className="list-unstyled mb-3">
                                <li className="list-inline-item text-muted me-3 d-inline-flex align-items-center"><FiTag className="text-dark h6 me-1 mb-0"/><Link to="#" className="text-muted">Productivity</Link>, <Link to="#" className="text-muted">Brand</Link></li>
                                <li className="list-inline-item text-muted d-inline-flex align-items-center"><FiCalendar className="text-dark h6 me-1 mb-0"/>19th June, 2023</li>
                            </ul>
                            <h4 className="heading">Unica Bowls From the South Pacific.</h4>
                            <p className="para-desc text-muted">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-6">
                        <img src={work1} className="img-fluid rounded shadow" alt=""/>
                        <img src={work2} className="img-fluid rounded shadow mt-4" alt=""/>
                        <img src={work3} className="img-fluid rounded shadow mt-4" alt=""/>
                        <img src={work4} className="img-fluid rounded shadow mt-4" alt=""/>
                    </div>

                    <div className="col-md-6">
                        <div className="section-title py-md-5 pt-5 ms-lg-4 sticky-bar">
                            <p className="para-desc text-muted">If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page (layout or type area).</p>
                        
                            <h5>Task :</h5>

                            <p className="para-desc text-muted">There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space.</p>
                        
                            <div className="work-details rounded shadow p-4 mt-4">
                                <ul className="list-unstyled mb-0">
                                    <li className="mb-0 ms-0">
                                        <h6 className="d-inline-block mb-0">Client :</h6>
                                        <span className="text-muted">Alita Margarate</span>
                                    </li>
                                    <li className="mt-3 mb-0 ms-0">
                                        <h6 className="d-inline-block mb-0">Category :</h6>
                                        <span className="text-muted">Web Design</span>
                                    </li>
                                    <li className="mt-3 mb-0 ms-0">
                                        <h6 className="d-inline-block mb-0">Subject :</h6>
                                        <span className="text-muted">Web Design</span>
                                    </li>
                                    <li className="mt-3 mb-0 ms-0">
                                        <h6 className="d-inline-block mb-0">Date :</h6>
                                        <span className="text-muted">28th April, 2020</span>
                                    </li>
                                    <li className="mt-3 mb-0 ms-0">
                                        <h6 className="d-inline-block mb-0">Website :</h6>
                                        <span className="text-muted">www.yourdomain.com</span>
                                    </li>
                                    <li className="mt-3 mb-0 ms-0">
                                        <h6 className="d-inline-block mb-0">Location :</h6>
                                        <span className="text-muted">3/2/64 Mongus Street, UK</span>
                                    </li>
                                    <li className="mt-3 mb-0 ms-0">
                                        <h6 className="d-inline-block mb-0">Demo :</h6>
                                        <span className="text-muted"><Link to="#" className="text-primary">Demo link</Link></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container-fluid mt-100 mt-60 px-0">
                <div className="py-5 bg-light">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="d-flex align-items-center justify-content-between">
                                    <Link to="/work-detail" className="text-dark align-items-center">
                                        <span className="pro-icons"><FiArrowLeft className="fea icon-sm me-1"/></span>
                                        <span className="text-muted d-none d-md-inline-block">Web Development</span>
                                        <img src={work1} className="avatar avatar-small rounded shadow ms-2" style={{height:'auto'}} alt=""/>
                                    </Link>

                                    <Link to="/index" className="btn btn-lg btn-pills btn-icon btn-soft-primary"><FiHome className="icons"/></Link>
                                    
                                    <Link to="/work-detail" className="text-dark align-items-center">
                                        <img src={work3} className="avatar avatar-small rounded shadow me-2" style={{height:'auto'}} alt=""/>
                                        <span className="text-muted d-none d-md-inline-block">Web Designer</span>
                                        <span className="pro-icons"><FiArrowRight className="fea icon-sm ms-1"/></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}