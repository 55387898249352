import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-lines-two.png'

import Navbar from "../../components/navbar";
import HelpCenter from "../../components/helpCenter";
import GetInTuch from "../../components/getInTuch";
import Footer from "../../components/footer";
import FooterTopTwo from "../../components/footerTopTwo";
import ScrollTop from "../../components/scrollTop";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import { priceTwo } from "../../data/data";

import { MdOutlineCheckCircle } from '../../assets/icons/icons'
import { Typography } from "@mui/material";

export default function PagePricing() {
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const [plans, setPlans] = useState([]);
    useEffect(() => {
        const fetchPlans = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch('/api/plans/list');
            const json = await response.json();
            if (response.ok) {
                setPlans(json)
                console.log('json', json);
            }

        }
        fetchPlans()
    }, []);
      // Filter the plans based on whether the switch is yearly or monthly
      const filteredPlans = plans.filter((plan) => {
        return checked ? plan.year_month === "year" : plan.year_month === "month";
    });

    return (
        <>
            <Navbar navLight={true} />
            <section className="bg-half-170 bg-primary d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="heading-title text-center">
                                <h2 className="text-white title title-dark mb-3">Our Pricing Plans & Rates</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="features-absolute">
                        <div className="row justify-content-center">
                            <div className="row ">
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', // Horizontal centering
                                    alignItems: 'center',     // Vertical centering (if needed)
                                    marginBottom: '50px',     // Vertical centering (if needed)
                                }}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch
                                        color="default"
                                            checked={checked}
                                            onChange={handleChange}

                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}  label={
                                            <Typography sx={{ fontSize: '1.9rem', color: checked ? 'white' : 'white' }}>
                                              {checked ? "Yearly" : "Monthly"}
                                            </Typography>
                                          } sx={{ 
                                            color: checked ? 'white' : 'white', // Change label color based on checked state
                                          }} />
                                    </FormGroup>
                                </div>
                                {filteredPlans && filteredPlans.map((item) => {
                                    return (
                                        <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0" key={item.__id}>
                                            <div className="card pricing border-0 p-4 rounded-md shadow">
                                                <div className="card-body p-2">
                                                    <span className="py-2 px-4 d-inline-block bg-soft-primary h6 mb-0 text-primary rounded-lg">{item.title}</span>

                                                    <div><h2 className="fw-bold mb-0 mt-3">${item.plan_price}</h2>
                                                        <p className="text-muted">per {item.year_month}</p></div>
                                                    <p className="list-unstyled pt-3 border-top pricing-tag" dangerouslySetInnerHTML={{ __html: item.description }} ></p>

                                                    <div className="mt-4">
                                                        <div className="d-grid">
                                                            <Link to={`/signup?plan=${item.title}&monthly=${checked}`} className="btn btn-primary">Buy Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="container mt-100 mt-60">
                        <div className="row justify-content-center mb-4">
                            <div className="col-12">
                                <div className="section-title text-center mb-4 pb-2">
                                    <h4 className="title mb-4">frequently asked questions</h4>
                                    <p className="text-muted para-desc mx-auto mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                                </div>
                            </div>
                        </div>

                        <HelpCenter />

                        <div className="row mt-5 pt-md-5 justify-content-center">
                            <GetInTuch />
                        </div>
                    </div>
                    </div>
            </section>
            <FooterTopTwo />
            <Footer />
            <ScrollTop />
        </>
    )
}