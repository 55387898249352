import React, { useState } from 'react';
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-services.jpg'
import testi from '../../assets/images/bg/testi.jpg'

import Navbar from "../../components/navbar";
import Footer from '../../components/footer';
import ScrollTop from '../../components/scrollTop';

import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

import { clientData, servicesData, sponsorsData } from '../../data/data';

import { FiArrowRight } from '../../assets/icons/icons'

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

export default function PageServices(){
    let [isOpen, setOpen] = useState(false);

    let settings = {
        container: '.tiny-single-item',
        items: 1,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 16,
      };

    return(
        <>
        <Navbar/>
        <section className="bg-half-170 d-table w-100 overflow-hidden" id="home">
            <div className="container">
                <div className="row justify-content-center pt-5">
                    <div className="col-12">
                        <div className="heading-title text-center">
                            <h2 className="title mb-3">Our Services</h2>
                            <p className="para-desc mx-auto text-muted">Feel free to adjust and customize these services based on the specific offerings and partnerships of your delivery app!</p>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="container mt-5">
                <div className="bg-half-260 d-table w-100 rounded-md shadow-lg" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
                    <div className="play-icon">
                        <Link to="#"  onClick={() => setOpen(true)} className="lightbox play-btn video-play-icon">
                            <i className="mdi mdi-play text-primary rounded-circle bg-white shadow-lg"></i>
                        </Link>
                    </div>
                    <ModalVideo
                        channel="youtube"
                        youtube={{ mute: 0, autoplay: 0 }}
                        isOpen={isOpen}
                        videoId="yba7hPeTSjk"
                        onClose={() => setOpen(false)} 
                    />
                </div>
            </div>
        </section>

        <section className="section pt-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-primary">Our Services</h6>
                            <h4 className="title mb-4">Operation Track <br/> more than a delivery app</h4>
                            {/* <p className="text-muted para-desc mx-auto mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p> */}
                        </div>
                    </div>
                </div>

                <div className="row">
                    {servicesData.map((item, index) =>{
                        let Icon = item.icon
                        return(
                        <div className="col-lg-4 col-md-6 mt-4 pt-2" key={index}>
                            <div className="card features feature-primary core-feature rounded p-4 text-center border-0">
                                <div className="icon text-center rounded-circle mx-auto">
                                    <h3 className="mb-0"><Icon/></h3>
                                </div>
                                <div className="card-body p-0 mt-4">
                                    <h5>{item.title}</h5>
                                    <p className="text-muted mb-0">{item.desc}</p>
                                    <div className="mt-2">
                                        <Link to="#" className="link">Read More <FiArrowRight className="fea icon-sm"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>

            <div className="container-fluid mt-100 mt-60">
                <div className="py-5 rounded" style={{backgroundImage:`url(${testi})`, backgroundPosition:'center'}}>
                    <div className="container">
                        <div className="row py-md-5 py-4">
                            <div className="col-lg-4 col-md-6">
                                <div className="bg-white rounded px-4 py-5">
                                    <div className="tiny-single-item">
                                        <TinySlider settings={settings}>
                                            {clientData.map((item, index) =>{
                                                return(
                                                <div className="tiny-slide" key={index}>
                                                    <div className="customer-testi">
                                                        <p className="text-muted fst-italic">{item.desc}</p>
                                                        <div className="d-flex align-items-center mt-4">
                                                            <img src={item.image} className="img-fluid avatar avatar-small rounded-circle shadow" alt=""/>
                                                            <div className="flex-1 ms-3">
                                                                <h6 className="text-primary mb-0">{item.name}</h6>
                                                                <small className="text-muted">{item.title}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            })}
                                        </TinySlider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-primary">Sponsors</h6>
                            <h4 className="title mb-4">Less work, More flow.</h4>
                            <p className="text-muted para-desc mx-auto mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-9 mt-4">
                        <ul className="list-unstyled text-center mb-0">
                            {sponsorsData.map((item, index) =>{
                                return(
                                    <li className="list-inline-item m-2" key={index}>
                                        <div className="d-flex align-items-center rounded-lg shadow py-3 px-4">
                                            <img src={item.image} className="avatar avatar-ex-sm" alt=""/>
                                            <h6 className="mb-0 ms-2">{item.title}</h6>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
         <Footer/>                   
         <ScrollTop/>
        </>
    )
}