import React from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-blog.jpg'
import Navbar from "../../components/navbar";
import { blogData } from "../../data/data";

import { FiCalendar, FiClock, FiArrowRight, FiRefreshCw } from '../../assets/icons/icons'
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

export default function Blogs(){
    return(
        <>
        <Navbar navLight={true}/>

        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="bg-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="heading-title text-center">
                            <h1 className="text-white title-dark fw-bold">Blogs & News</h1>
                            <div className="subcribe-form my-4">
                                <form>
                                    <div className="mb-0">
                                        <input type="text" id="name" name="name" className="border bg-white rounded-pill" style={{opacity: '0.9'}} required placeholder="Search keywords..."/>
                                        <button type="submit" className="btn btn-pills btn-primary">Search</button>
                                    </div>
                                    <p className="text-white-50 mb-0 mt-4"><span className="text-light title-dark">Keywords :</span> Web Developer, Web Designer, Php Developer, IOS Developer, etc...</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container">
                <div className="row">
                    {blogData.map((item, index) =>{
                        return(
                            <div className="col-lg-4 col-md-6 mb-4 pb-2" key={index}>
                                <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                                    <div className="position-relative overflow-hidden">
                                        <img src={item.image} className="img-fluid" alt=""/>
                                        <Link to="#" className="tag py-1 px-4 d-inline-block h6 mb-0 text-white rounded-lg">{item.tag}</Link>
                                    </div>
                                    <div className="card-body p-4">
                                        <ul className="list-unstyled mb-2">
                                            <li className="list-inline-item text-muted small me-3 d-inline-flex align-items-center"><FiCalendar className="text-dark h6 me-1 mb-0"/>{item.date}</li>
                                            <li className="list-inline-item text-muted small d-inline-flex align-items-center"><FiClock className="text-dark h6 me-1 mb-0"/>{item.time}</li>
                                        </ul>
                                        <Link to={`/blog-detail/${item.id}`} className="text-dark title h5">{item.title}</Link>
                                        
                                        <div className="mt-2">
                                            <Link to="#" className="link">Read more <FiArrowRight className="fea icon-sm"/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="col-12 text-center">
                        <Link to="#" className="text-primary h6 mb-0">Load more <FiRefreshCw /></Link>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}