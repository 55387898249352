import React from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-about.jpg'
import Navbar from "../../components/navbar";
import Footer from '../../components/footer';
import coworking from '../../assets/images/svg/coworking.svg'
import about from '../../assets/images/1.png'
import blog from '../../assets/images/blog/single.jpg'
import first from '../../assets/images/about/about-first.png'
import second from '../../assets/images/about/about-second.png'
import third from '../../assets/images/about/what-we-offer.png'
import forth from '../../assets/images/about/key-features.png'
import fifth from '../../assets/images/about/our-values.png'
import sixth from '../../assets/images/about/join-us-our-journey.png'
import seventh from '../../assets/images/about/about-seventh-new.png'
import blog1 from '../../assets/images/blog/1.jpg'
import blog2 from '../../assets/images/blog/2.jpg'
import client from '../../assets/images/client/05.jpg'


import { aboutData, aboutImage, startUpCounter, teamData } from "../../data/data";

import { FiArrowRight, FiMail, MdOutlineCheckCircle } from '../../assets/icons/icons'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import CountUp from 'react-countup';
import ScrollTop from "../../components/scrollTop";

export default function PageAbout() {
    return (
        <>
            <Navbar navLight={true} />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})` }}>
                <div className="bg-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="heading-title text-center">
                                <h2 className="text-white title  title-dark mb-3">About us</h2>
                                <p className="para-desc mx-auto text-white-50">More Than a delivery App.</p>
                                {/* <div className="mt-4 pt-2">
                                <Link to="#" className="btn btn-light">Read Our Story</Link>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container mt-100 mt-60">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-5">
                            <img src={first} className="img-fluid" alt="" />
                        </div>

                        <div className="col-lg-6 col-md-7">
                            <div className="section-title">
                                <h1 className="title mb-3">Welcome to Operation Track</h1>
                                <p className="para-desc text-muted">At Operation Track, we are revolutionizing last-mile delivery services with cutting-edge technology and unparalleled dedication to customer satisfaction. Our mission is to ensure that your packages reach their destination swiftly, safely, and efficiently, every time.
                                </p>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-100 mt-60">
                    <div className="row align-items-center">
                        <div className="col-md-5 order-1 order-md-2">
                            <img src={second} className="img-fluid" alt="" />
                        </div>

                        <div className="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0 order-2 order-md-1">
                            <div className="section-title">
                                <h1 className="title mb-3">Who We Are </h1>
                                <p className="para-desc text-muted">Operation Track was founded with a vision to transform the logistics landscape. Our team comprises industry veterans, tech enthusiasts, and customer service professionals who are passionate about redefining delivery standards. We believe that every delivery is a promise, and we are committed to keeping that promise through innovation, precision, and reliability.</p>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-100 mt-60">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-5">
                            <img src={seventh} className="img-fluid" alt="" />
                        </div>

                        <div className="col-lg-6 col-md-7">
                            <div className="section-title">
                                <h1 className="title mb-3">Our Mission
                                </h1>
                                <p className="para-desc text-muted">Our mission is to provide seamless, optimized, and transparent delivery solutions that exceed customer expectations. We aim to bridge the gap between businesses and their customers, enhancing the delivery experience with our state-of-the-art route optimization technology and proactive customer communication.
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-100 mt-60">
                    <div className="row align-items-center">
                        <div className="col-md-5 order-1 order-md-2">
                            <img src={third}  className="img-fluid" alt="" />
                        </div>

                        <div className="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0 order-2 order-md-1">
                            <div className="section-title">
                                <h1 className="title mb-3">What We Offer
                                </h1>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Advanced Route Optimization:</b> Our sophisticated algorithms ensure the most efficient routes, minimizing delivery times and reducing carbon footprints.</li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Real-Time Tracking:</b> Stay informed with real-time updates on your delivery status, from dispatch to doorstep.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Reliable Service:</b> With a network of dedicated drivers and robust logistics infrastructure, we guarantee timely and secure deliveries.</li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Customer-Centric Approach:</b> We prioritize your needs, offering flexible solutions and exceptional support to ensure your satisfaction.</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-100 mt-60">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-5">
                            <img src={forth} className="img-fluid" alt="" />
                        </div>

                        <div className="col-lg-6 col-md-7">
                            <div className="section-title">
                                <h1 className="title mb-3">Key Features
                                </h1>
                                <p className="para-desc text-muted">Our app is packed with features designed to streamline and enhance your delivery experience:

                                </p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Customer Portal:</b> Easily manage and track your deliveries.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Delivery Tracking:</b> Real-time updates on the status of your deliveries.

                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Dispatch Management:</b> Efficiently organize and dispatch deliveries.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Driver ETA:</b> Get accurate estimated times of arrival for your deliveries.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Driver Management:</b> Tools to manage and monitor your drivers.

                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Vehicle Type:</b> Select the best vehicle type for your deliveries.

                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Checklist to Verify Pickup/Delivery:</b> Ensure all items are accounted for.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Address Book:</b> Save and manage addresses for frequent deliveries.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Customized Task Completion Form:</b> Tailor task forms to fit your needs.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Mobile Signature Capture:</b> Securely capture signatures on delivery.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Routing and Optimization:</b> Optimize routes for faster deliveries.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Surveys & Feedback:</b> Collect valuable feedback from customers.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Driver Feedback Loop:</b> Continuous improvement through driver feedback.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Tags on Delivery Types:</b> Easily categorize different types of deliveries.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Vehicle Capacity:</b> Manage and optimize vehicle load capacities.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Life-Cycle Tracking and Auditing:</b> Track and audit the entire delivery life cycle.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>API:</b> Integrate seamlessly with other systems and applications.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Message Response Re-routing:</b> Re-route responses to chosen emails (settings required).
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Anonymize Telephone Number:</b> Protect privacy by anonymizing phone numbers.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Tutorials:</b> Access helpful tutorials to make the most of our app.
                                    </li>
                                </ul>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-100 mt-60">
                    <div className="row align-items-center">
                        <div className="col-md-5 order-1 order-md-2">
                            <img src={fifth} className="img-fluid" alt="" />
                        </div>

                        <div className="col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0 order-2 order-md-1">
                            <div className="section-title">
                                <h1 className="title mb-3">Our Values

                                </h1>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Innovation:</b> We continuously seek new ways to improve and innovate, staying ahead in the ever-evolving logistics industry.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Integrity:</b> Trust and transparency are at the core of our operations. We are committed to honesty and accountability in all our interactions.

                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Excellence:</b> We strive for excellence in everything we do, from the technology we develop to the services we provide.
                                    </li>
                                    <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle" /></span><b>Sustainability:</b> We are dedicated to sustainable practices that minimize environmental impact and contribute to a greener future.
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-10">
                            <div className="section-title">


                                <h2 className="mb-4">Join Us on Our Journey
                                </h2>
                                <p className="text-muted mb-0">We invite you to join us on our journey to revolutionize last-mile delivery. Whether you’re a business looking for reliable delivery solutions or a customer eager for timely and transparent service, Operation Track is here to deliver beyond expectations.</p>
                            </div>

                            <div className="position-relative mt-4 pt-2">
                                <img src={sixth} className="rounded img-fluid mx-auto d-block" alt="" />



                            </div>

                           



                        </div>
                    </div>
                </div>


                <div className="container mt-100 mt-60">
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
                    >
                        <Masonry>
                            {aboutImage.map((item, index) => {
                                return (
                                    <div className=" spacing picture-item" key={index}>
                                        <div className="card border-0 position-relative d-block shadow overflow-hidden rounded">
                                            <div className="card-body p-0">
                                                <img src={item} className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>


            </section>
            <Footer />
            <ScrollTop />
        </>
    )
}
