import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import swal from "sweetalert";

import { FiHome, FaFacebookSquare, SlSocialGoogle } from '../../assets/icons/icons'
import bg1 from '../../assets/images/bg/bg-lines-one.png'
import logoDark from '../../assets/images/logo-dark.png'
import optracklogo from '../../assets/images/optracklogo.png'
import newlogo from '../../assets/images/newoptracklogo.png'
import Navbar from "../../components/navbar";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField } from "@mui/material";


function CheckoutForm() {
  
    const [email, setEmail] = useState('');
    const [progress, setProgress] = useState(false);
    
    const [title,setTitle] = useState('')
    const [queryParameters] = useSearchParams()
    const [description,setDescription] = useState('')
    const [price,setPrice] = useState('')

    const [plan_id, setPlanId] = useState(queryParameters.get("plan"));
    const [plan_type, setPlanType] = useState(queryParameters.get("monthly"));
   
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    let errorsObj = { email: '', password: '' };
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('customer');
    const [showPassword, setShowPassword] = useState(false);

    // Function to toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };
  
  // stripe items
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    const fetchPlan = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch('/api/plans/name/'+plan_id,{})
        const json = await response.json();
        if(response.ok){
           
            setTitle(json.title)
            setDescription(json.description)
            if(plan_type)
            setPrice(json.monthly_plan_price)
        else
            setPrice(json.yearly_plan_price)
           
        }
        
    }
    fetchPlan()
}, []);

  // main function
  const createSubscription = async () => {
    try {

        setProgress(true)
      
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch('/api/stripe/createSubscription', {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ first_name, last_name, email, password, plan_id, plan_type, paymentMethod: paymentMethod?.paymentMethod?.id })
      }).then((res) => res.json());
      console.log(response);
      if (response?.error) {
        setProgress(false)
       
        swal(response.error, '', 'error')
      } else {
        setProgress(false)
       
        swal('Signup Successful', '', 'success')
        window.location = process.env.REACT_APP_WEB_URL;
      }
    } catch (error) {
        setProgress(false)

        swal(error, '', 'error')
      console.log(error);
    }
  };

  return (
    <section className="bg-half-130 d-table w-100 bg-light" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>

<Navbar navLight={false}/>
        <section className="bg-half-50 bg-primary d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="heading-title text-center">
                            <h2 className="text-white title title-dark mb-3">Signup</h2>
                            {/* <p className="para-desc mx-auto text-white-50">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <div className="container">



        <div className="row justify-content-center">

            <div className="col-lg-5 col-md-8">

                <img src={newlogo} className="mx-auto d-block"  style={{height:'60px'}} alt="" />
                <div className="card login-page bg-white shadow mt-4 rounded border-0">
                    <div className="card-body">
                        <h4 className="text-center">Sign Up for {title} ${price}</h4>
                        <p className="list-unstyled pt-3 border-top pricing-tag" dangerouslySetInnerHTML={{__html: description}} ></p>
                        <div className="login-form mt-4" >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3 position-relative">
                                        <label className="form-label">First name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="First Name" name="s" required="" value={first_name}
                                            onChange={(e) => setFirstName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3 position-relative">
                                        <label className="form-label">Last name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="Last Name" name="s" required="" value={last_name}
                                            onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3 position-relative">
                                        <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                        <input type="email" className="form-control" placeholder="Email" name="email" required="" value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3 position-relative">
                                        <label className="form-label">Password <span className="text-danger">*</span></label>
                                        <TextField type={showPassword ? 'text' : 'password'} className="form-control" placeholder="Password" required="" value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            } 
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={togglePasswordVisibility}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            />
                                    </div>
                                </div>
                                {/* <div className="col-md-12">
                                    <div className="mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">I Accept <Link to="#" className="text-primary">Terms And Condition</Link></label>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <StripeElementWrapper>

                                    <CardElement />

                                </StripeElementWrapper> */}
                                <div className="mb-3 position-relative">
  <label className="form-label">Card Details <span className="text-danger">*</span></label>
  <div className="form-control" style={{ padding: "0.375rem 0.75rem", backgroundColor: "#f8f9fa", border: "1px solid #ced4da", borderRadius: "4px" }}>
    <CardElement
      options={{
        style: {
          base: {
            fontSize: "16px",
            color: "#495057",
            "::placeholder": {
              color: "#6c757d",
            },
          },
          invalid: {
            color: "#dc3545",
          },
        },
      }}
    />
  </div>
</div>

                                    {/* <CardElement /> */}

                                <div className="col-md-12 d-flex justify-content-center">
                                    {progress && <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>}

                                   
                                    {!progress &&  <div className="d-grid">
      <button className="btn btn-primary" onClick={createSubscription} disabled={!stripe} type="button">
        Subscribe
      </button>
      </div>}
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    
 
  );
}

export default CheckoutForm;