import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/comingsoon.jpg'
import logo from '../../assets/images/logo-light.png'

import {FiHome} from '../../assets/icons/icons'

export default function ComingSoon(){
   
    let [days, setDays] = useState(0);
    let [hours, setHours] = useState(0);
    let [minutes, setMinutes] = useState(0);
    let [seconds, setSeconds] = useState(0);
  
    let deadline = "December, 31, 2024";
  
    let getTime = () => {
      let time = Date.parse(deadline) - Date.now();
  
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };
  
    useEffect(() => {
      let interval = setInterval(() => getTime(deadline), 1000);
  
      return () => clearInterval(interval);
    });

    return(
        <>
        <div className="back-to-home rounded d-none d-sm-block">
            <Link to="/index" className="btn btn-icon btn-primary"><FiHome className="icons"/></Link>
        </div>

        <section className="bg-home d-flex align-items-center" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="bg-overlay"></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 text-center">
                        <img src={logo} height="30" alt=""/>
                        <div className="display-3 fw-bold text-white title-dark mt-4 mb-4">Coming soon...</div>
                        <p className="text-white-50 para-desc mx-auto">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        <div id="countdown">
                            <ul className="count-down list-unstyled">
                                <li id="days" className="count-number list-inline-item m-2">{days}<p className='count-head'>Days</p></li>
                                <li id="hours" className="count-number list-inline-item m-2">{hours}<p className='count-head'>hours</p></li>
                                <li id="mins" className="count-number list-inline-item m-2">{minutes}<p className='count-head'>minutes</p></li>
                                <li id="secs" className="count-number list-inline-item m-2">{seconds}<p className='count-head'>seconds</p></li>
                                <li id="end" className="h1"></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row mt-4 pt-2">
                    <div className="col-12 text-center">
                        <div className="subcribe-form">
                            <form action="index.html">
                                <input name="email" id="email" type="email" className="rounded-pill" required="" placeholder="Your email :"/>
                                <button type="submit" className="btn btn-primary rounded-pill">Subscribe</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
        </>
    )
}