import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { priceTwo } from '../data/data'

import { MdOutlineCheckCircle } from '../assets/icons/icons'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function PricingOne() {
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const [plans, setPlans] = useState([]);
    useEffect(() => {
        const fetchPlans = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch('/api/plans/list');
            const json = await response.json();
            if (response.ok) {
                setPlans(json)
                console.log('json', json);
            }

        }
        fetchPlans()
    }, []);
     // Filter the plans based on whether the switch is yearly or monthly
     const filteredPlans = plans.filter((plan) => {
        return checked ? plan.year_month === "year" : plan.year_month === "month";
    });
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="section-title text-center mb-4 pb-2">
                        <h6 className="text-primary">Our Pricing</h6>
                        <h4 className="title mb-4">Comfortable Plans & Rates</h4>
                        <p className="text-muted para-desc mx-auto mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                    </div>
                </div>
            </div>

            <div className="row ">
                <div style={{
      display: 'flex',
      justifyContent: 'center', // Horizontal centering
      alignItems: 'center',     // Vertical centering (if needed)
    }}>
            <FormGroup>
                                        <FormControlLabel control={<Switch
                                            checked={checked}
                                            onChange={handleChange}

                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />} label={checked ? "Yearly" : "Monthly"} />
                                    </FormGroup>
                                    </div>
                {filteredPlans && filteredPlans.map((item) => {
                    return (
                        <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0" key={item.__id}>
                            <div className="card pricing border-0 p-4 rounded-md shadow">
                                <div className="card-body p-2">
                                    <span className="py-2 px-4 d-inline-block bg-soft-primary h6 mb-0 text-primary rounded-lg">{item.title}</span>
                                    

                                  <h2 className="fw-bold mb-0 mt-3">${item.plan_price}</h2>
                                            <p className="text-muted">per {item.year_month}</p>
                                       
                                    <p className="list-unstyled pt-3 border-top pricing-tag" dangerouslySetInnerHTML={{ __html: item.description }} ></p>
                                    <div className="mt-4">
                                        <div className="d-grid">
                                            <Link to={`/signup?plan=${item.title}&monthly=${checked}`} className="btn btn-primary">Buy Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}