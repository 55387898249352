import React from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-lines-one.png'
import bg2 from '../../assets/images/bg/bg-lines-two.png'
import heroImg from '../../assets/images/apps/hero.png'
import Welcome from '../../assets/images/welcome1.png'
import point from '../../assets/images/application-key-features1.png'
import appsTwo from '../../assets/images/apps/1.png'

import Navbar from "../../components/navbar";
import CompanyLogo from "../../components/companyLogo";
import PricingOne from "../../components/pricingOne";
import HelpCenter from "../../components/helpCenter";
import GetInTuch from "../../components/getInTuch";
import Analytics from "../../components/analytics";
import FooterTop from "../../components/footerTop";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import AnalyticsTwo from "../../components/analyticsTwo";

import { FaApple, RiGooglePlayLine, FiArrowRight } from '../../assets/icons/icons'

import { appFeature, keyFeature } from "../../data/data";

export default function IndexApp(){
    return(
        <>
        <Navbar/>

        <section className="bg-half-130 d-table w-100 bg-light" style={{backgroundImage:`url(${bg1})` , backgroundPosition:'center'}}>
            <div className="container">
                <div className="row align-items-center mt-5 mt-lg-0">
                    <div className="col-lg-8 col-md-7">
                        <div className="heading-title">
                            <h4 className="display-4 fw-bold">Welcome to Operation Track!</h4>
                            <p className="para-desc text-muted">Efficiently manage your deliveries and tasks with our powerful delivery management software, Operation Track. Designed for businesses of all sizes that manage deliveries or perform tasks across multiple locations, our software simplifies the complexities of logistics and task management. As your business grows, Operation Track seamlessly scales with you, adapting to increased demand without skipping a beat. Whether you’re dispatching, tracking, or updating, our platform ensures everything runs smoothly from start to finish. Experience real-time updates, seamless integration, and scalable efficiency—all at your fingertips. Start optimizing your operations today with Operation Track, where every delivery and task is perfectly managed!</p>
                            <div className="mt-3">
                                <Link to="#" className="btn btn-primary me-2 mt-2"><FaApple /> App Store</Link>
                                <Link to="#" className="btn btn-soft-primary mt-2"><RiGooglePlayLine /> Play Store</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <img src={Welcome}
                         style={{maxWidth:'135%',height:'auto'}}  alt=""/>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-primary">Features</h6>
                            <h4 className="title mb-4">Streamline Your Delivery Operations with Operation Track
                            </h4>
                            <p className="text-muted para-desc mx-auto mb-0">Unlock the full potential of your delivery and task management with Operation Track. Our comprehensive software features a suite of powerful tools designed to enhance efficiency and customer satisfaction. From a user-friendly Customer Portal to real-time Delivery Tracking and efficient Dispatch Management, every feature is crafted to optimize your operations. Enhance driver accountability with Mobile Signature Capture, and improve route efficiency with intelligent Routing solutions. With seamless Scheduling and accurate Driver ETAs, your business can deliver exceptional service consistently. Plus, our integrated Surveys & Feedback system ensures you capture valuable customer insights to keep improving. Choose Operation Track and transform your delivery management into a streamlined, scalable success.</p>
                        </div>
                    </div>
                </div>

                <div className="row">

                    {appFeature.map(( item, index ) =>{
                        let Icon = item.icon
                        return(
                            <div className="col-lg-4 col-md-6 mt-4 pt-2" key={index}>
                                <div className="card border-0 text-center features feature-primary rounded">
                                    <div className="icon text-center mx-auto rounded-md">
                                        <h3 className="mb-0"><Icon/></h3>
                                    </div>

                                    <div className="card-body p-0 mt-4">
                                        <h5>{item.title}</h5>
                                        <p className="text-muted mb-0">{item.desc}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <Analytics/>
            </div>
        </section>

        <section className="section bg-primary" style={{backgroundImage:`${bg2}`, backgroundPosition:'center'}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-white title-dark">Features</h6>
                            <h4 className="title text-white title-dark mb-4">Application Key Features</h4>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-6">
                        <div className="row text-md-end text-center">
                            {keyFeature.slice(0,4).map(( item, index ) =>{
                                let Icon = item.icon
                                return(
                                    <div className="col-12 mt-4 pt-2" key={index}>
                                        <div className="card features feature-light bg-transparent border-0 bg-transparent">
                                            <div className="icon text-center ms-md-auto me-md-0 mx-auto bg-soft-light text-light rounded-circle">
                                                <h3 className="mb-0"><Icon /></h3>
                                            </div>
                                            <div className="card-body p-0 mt-3">
                                                <h5 className="text-white title-dark">{item.title}</h5>
                                                <p className="text-white-50 mb-0">{item.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="col-lg-4 text-center">
                        <img src={point} style={{height:'800px',maxWidth:'100%'}} alt=""/>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="row text-md-start text-center">
                            {keyFeature.slice(4,9).map(( item, index ) =>{
                                let Icon = item.icon
                                return(
                                    <div className="col-12 mt-4 pt-2" key={index}>
                                        <div className="card features feature-light bg-transparent border-0 bg-transparent">
                                            <div className="icon text-center me-md-auto ms-md-0 mx-auto bg-soft-light text-light rounded-circle">
                                                <h3 className="mb-0"><Icon /></h3>
                                            </div>
                                            <div className="card-body p-0 mt-3">
                                                <h5 className="text-white title-dark">{item.title}</h5>
                                                <p className="text-white-50 mb-0">{item.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container">
                <AnalyticsTwo/>
            </div>

            <div className="container mt-100 mt-60">
                <PricingOne/>
            </div>

            <div className="container mt-100 mt-60">
                {/* <HelpCenter/> */}

                <div className="row mt-5 pt-md-5 justify-content-center">
                    <GetInTuch/>
                </div>
            </div>
        </section>
        <FooterTop/>
        <Footer/>
        <ScrollTop/>
        </>
    )
}