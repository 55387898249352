import React from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-lines-one.png'

import { FiThumbsUp } from '../../assets/icons/icons'

export default function ThankYou(){
    return(
        <>
        <section className="bg-home d-flex bg-light align-items-center" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10 text-center">
                        <div className="features feature-primary text-center">
                            <div className="icon text-center rounded-circle mx-auto" style={{height: '90px', width:'90px'}}>
                                <h1 className="mb-0"><FiThumbsUp style={{lineHeight:'90px'}}/></h1>
                            </div>
                        </div>
                        <h1 className="my-4 fw-bold">Thank You</h1>
                        <p className="text-muted para-desc mx-auto">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        <Link to="/index" className="btn btn-soft-primary mt-3">Go To Home</Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}