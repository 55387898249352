import React,{useState} from "react";
import { Link } from "react-router-dom";

import { portfolio, portfolioImage } from "../data/data";

import Lightbox from 'react-18-image-lightbox';
import "react-18-image-lightbox/style.css"

export default function ClassicPortfolio({container,grid,col}){
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [open, setIsOpen] = useState(false);

    const handleMovePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + portfolioImage.length - 1) % portfolioImage.length);
    };

    const handleMoveNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % portfolioImage.length);
    };

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setIsOpen(true);
    };
    
    const currentImage = portfolioImage[currentImageIndex - 1];

    const matchCategory = (category) => {
        setSelectedCategory(category);
    };

    const filteredData = selectedCategory
        ? portfolio.filter((item) => item.category === selectedCategory)
        : portfolio;
    return(
        <>
         <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 filters-group-wrap">
                    <div className="filters-group">
                        <ul className="container-filter categories-filter list-inline mb-0 filter-options text-center">
                            <li className={`${selectedCategory === null ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory(null)}>All</li>
                            <li className={`${selectedCategory === 'branding' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('branding')}>Branding</li>
                            <li className={`${selectedCategory === 'designing' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('designing')}>Designing</li>
                            <li className={`${selectedCategory === 'photography' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('photography')}>Photography</li>
                            <li className={`${selectedCategory === 'development' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('development')}>Development</li>
                        </ul>
                    </div>
                </div>    
            </div>
        </div>

        <div className={container}>
            <div className="row justify-content-center">
                <div className={col}>
                    <div id="grid" className={grid}>
                        {filteredData.map((item, index) =>{
                            return(
                            <div className="col-12 mt-4 pt-2 picture-item" data-groups='["designing"]' key={index}>
                                <div
                                    className="card work-container work-primary work-border position-relative overflow-hidden shadow rounded border-0">
                                    <div className="card-body p-0">
                                        <Link to="#" onClick={() => handleImageClick(item.id)} className="lightbox">
                                            <img src={item.image} className="img-fluid" alt=""/>
                                        </Link>
                                        <div className="overlay-work bg-dark"></div>
                                        <div className="content p-3">
                                            <h6 className="tag text-muted mb-1">{item.name}</h6>
                                            <Link to="#" className="title text-dark d-block h5 mb-0">{item.title}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    {open && (
                        <Lightbox
                            mainSrc={currentImage}
                            prevSrc={portfolioImage[(currentImageIndex + portfolioImage.length - 1) % portfolioImage.length]}
                            nextSrc={portfolioImage[(currentImageIndex + 1) % portfolioImage.length]}

                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                        />
                        )}
                    </div>
                    <div className="row mt-4 pt-2">
                        <div className="col-12">
                            <ul className="pagination justify-content-center mb-0">
                                <li className="page-item ms-0"><Link className="page-link" to="#" aria-label="Previous">Prev</Link></li>
                                <li className="page-item ms-0 active"><Link className="page-link" to="#">1</Link></li>
                                <li className="page-item ms-0"><Link className="page-link" to="#">2</Link></li>
                                <li className="page-item ms-0"><Link className="page-link" to="#">3</Link></li>
                                <li className="page-item ms-0"><Link className="page-link" to="#" aria-label="Next">Next</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}