import React from "react";
import { Link } from "react-router-dom";

export default function GetInTuch(){
    return(
        <div className="col-12 text-center">
            <div className="section-title">
                <h4 className="title mb-4">Have Question ? Get in touch!</h4>
                <p className="text-muted para-desc mx-auto"></p>
                <Link to="/contact" className="btn btn-primary mt-4"><i className="mdi mdi-phone"></i> Contact us</Link>
            </div>
        </div>
    )
}