import React, { useState } from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-lines-one.png'
import bg2 from '../../assets/images/bg/bg-lines-two.png'
import ceo from '../../assets/images/client/ceo.jpg'
import sign from '../../assets/images/client/sign.png'
import emails from '../../assets/images/svg/emails.svg'

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import { FiMail } from '../../assets/icons/icons'

import { accordionData, teamData } from "../../data/data";

export default function PageTeam(){
    let [activeIndex, setActiveIndex] = useState(1)
    return(
        <>
        <Navbar/>
        <section className="bg-half-170 d-table bg-light w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="heading-title text-center">
                            <h2 className="title mb-3">Meet Our Team</h2>
                            <p className="para-desc mx-auto text-muted">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                            <div className="mt-4 pt-2">
                                <Link to="#" className="btn btn-soft-primary d-inline-flex align-items-center"><FiMail className="align-middle h5 me-1 mb-0"/>Contact us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-primary">Our Team</h6>
                            <h4 className="title mb-4">Our Professionals</h4>
                            <p className="text-muted para-desc mx-auto mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {teamData.map((item, index) =>{
                        return(
                        <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2" key={index}>
                            <div className="card text-center team border-0 shadow rounded overflow-hidden">
                                <img src={item.image} className="img-fluid" alt=""/>
                                <div className="card-body py-3 content">
                                    <h5 className="mb-0">{item.name}</h5>
                                    <h6 className="text-muted mb-0">{item.title}</h6>
                                </div>
                                <ul className="list-unstyled team-social mb-0">
                                    <li><Link to="mailto:contact@example.com" className="btn btn-icon btn-pills btn-soft-primary"><FiMail className="icons"/></Link></li>
                                </ul>
                            </div>
                        </div>
                        )
                    })}

                    <div className="col-12 text-center mt-4 pt-2">
                        <div className="alert alert-light alert-pills" role="alert">
                            <span className="badge bg-danger rounded-pill me-1">Jobs</span>
                            <span className="content">We Are Hiring <Link to="#" className="text-primary"> Send your CV / Resume</Link></span>
                         </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-100 mt-60">
                <div className="rounded py-5 bg-primary shadow" style={{backgroundImage:`url(${bg2})`, backgroundPosition:'center'}}>
                    <div className="container py-md-4">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-5">
                                <img src={ceo} className="img-fluid rounded shadow" alt=""/>
                            </div>

                            <div className="col-lg-8 col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ms-lg-4">
                                    <h4 className="title fw-bold text-white title-dark mb-4">Our Director’s Message</h4>
                                    <p className="text-white-50 para-desc">With a great variety of layouts and sections to build a complete website. Inertia offers a modern design that feels fresh and professional. Code examples for core and new components. Class utilities with new functionality.</p>
                                    <h5 className="text-light title-dark mt-4">- Cristina Murphy <span className="text-white-50 h6 ms-2">C.E.O</span></h5>
                                    <img src={sign} height="36" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="me-lg-4">
                            <img src={emails} className="img-fluid" alt=""/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="section-title mb-4 pb-2">
                            <h4 className="title mb-4">Now Engage Clients With IT Solutions</h4>
                            <p className="text-muted para-desc mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        </div>

                        <div className="accordion">
                            {accordionData.map((item,index) =>{
                                return(
                                <div className="accordion-item rounded mt-2" key={index}>
                                    <h2 className="accordion-header">
                                        <button className={`${activeIndex === item.id ? '' : 'collapsed' } accordion-button border-0 bg-light`} type="button" onClick={()=>setActiveIndex(item.id)}>
                                           {item.title}
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className={`${activeIndex === item.id ? 'show' : ''} accordion-collapse border-0 collapse`}>
                                        <div className="accordion-body text-muted">
                                           {item.desc}
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}