import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./payment";
import FreeForm from "./freeform";
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe("pk_test_51OuJcl00CiZUGW6NdeF7DOr5KrZhmBEbOXuC3i5Cy1iqgUTm7FAjL8eVqasuTSOUwA5GB1FJMrMxrdFYGE0GksFH00l5FBq655"); // starts with pk_

function Signup() {
  const [queryParameters] = useSearchParams()
  const [free, setFreePlan] = useState(queryParameters.get("free")??false);
  return (
    <div>
      {!free? <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>:<FreeForm />}
     
    </div>
  );
}

export default Signup;