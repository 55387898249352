import React from "react";
import { Route, Routes } from "react-router-dom";
import 'bootstrap/scss/bootstrap.scss'
import './assets/scss/style.scss'
import './assets/css/materialdesignicons.min.css'
import Index from "./pages/index/index";
import IndexApp from "./pages/index/index-app";
// import IndexSaas from "./pages/index/index-saas";
// import IndexSoftware from "./pages/index/index-software";
// import IndexCareer from "./pages/index/index-career";
// import IndexCrypto from "./pages/index/index-crypto";
// import IndexStartup from "./pages/index/index-startup";
// import IndexBusiness from "./pages/index/index-business";
// import IndexSeoAgency from "./pages/index/index-seo-agency";
// import IndexFreelancer from "./pages/index/index-freelancer";
// import IndexBroker from "./pages/index/index-broker";
// import IndexDeveloper from "./pages/index/index-developer";
// import IndexStudio from "./pages/index/index-studio";
// import IndexHosting from "./pages/index/index-hosting";
// import IndexServices from "./pages/index/index-service";
import PageAbout from "./pages/page/page-about";
import PageServices from "./pages/page/page-services";
import PageTeam from "./pages/page/page-team";
import PagePricing from "./pages/page/page-pricing";
import PageFaqs from "./pages/page/page-faqs";
import Profile from "./pages/page/profile";
import ProfileSetting from "./pages/page/profile-setting";
import ProfilePayment from "./pages/page/profile-payment";
import BillingInvoice from "./pages/page/billing-invoice";
import CareerDetail from "./pages/page/career-detail";
import CareerApply from "./pages/page/career-apply";
import Blogs from "./pages/page/blogs";
import BlogDetail from "./pages/page/blog-detail";
import PageTerms from "./pages/page/page-terms";
import PagePrivacy from "./pages/page/page-privacy";
import ComingSoon from "./pages/page/page-comingsoon";
import Maintenance from "./pages/page/page-maintenance";
import PageError from "./pages/page/page-error";
import Login from "./pages/page/login";
import Signup from "./pages/page/signup";
import PaymentForm from "./pages/page/payment";
import ForgotPassword from "./pages/page/forgot-password";
import ThankYou from "./pages/page/thankyou";
import Contact from "./pages/page/contact";
import ClassicTwo from "./pages/portfolio/classic-two";
import ClassicThree from "./pages/portfolio/classic-three";
import ClassicFour from "./pages/portfolio/classic-four";
import ClassicFive from "./pages/portfolio/classic-five";
import ClassicSix from "./pages/portfolio/classic-six";
import GridTwo from "./pages/portfolio/grid-two";
import GridThree from "./pages/portfolio/grid-three";
import GridFour from "./pages/portfolio/grid-four";
import GridFive from "./pages/portfolio/grid-five";
import GridSix from "./pages/portfolio/grid-six";
import ModernTwo from "./pages/portfolio/modern-two";
import ModernThree from "./pages/portfolio/modern-three";
import ModernFour from "./pages/portfolio/modern-four";
import ModernFive from "./pages/portfolio/modern-five";
import ModernSix from "./pages/portfolio/modern-six";
import MasonryTwo from "./pages/portfolio/masonry-two";
import MasonryThree from "./pages/portfolio/masonry-three";
import MasonryFour from "./pages/portfolio/masonry-four";
import MasonryFive from "./pages/portfolio/masonry-five";
import MasonrySix from "./pages/portfolio/masonry-six";
import WorkDetail from "./pages/portfolio/work-detail";

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexApp/>}/>
      <Route path="/index"  element={<IndexApp/>}/>
      <Route path="/index-app" element={<IndexApp/>}/>
      {/* <Route path="/index-saas" element={<IndexSaas/>}/>
      <Route path="/index-software" element={<IndexSoftware/>}/>
      <Route path="/index-career" element={<IndexCareer/>}/>
      <Route path="/index-crypto" element={<IndexCrypto/>}/>
      <Route path="/index-startup" element={<IndexStartup/>}/>
      <Route path="/index-business" element={<IndexBusiness/>}/>
      <Route path="/index-seo-agency" element={<IndexSeoAgency/>}/>
      <Route path="/index-freelancer" element={<IndexFreelancer/>}/>
      <Route path="/index-broker" element={<IndexBroker/>}/>
      <Route path="/index-developer" element={<IndexDeveloper/>}/>
      <Route path="/index-studio" element={<IndexStudio/>}/>
      <Route path="/index-hosting" element={<IndexHosting/>}/>
      <Route path="/index-service" element={<IndexServices/>}/> */}
      <Route path="/page-about" element={<PageAbout/>}/>
      <Route path="/page-services" element={<PageServices/>}/>
      <Route path="/page-team" element={<PageTeam/>}/>
      <Route path="/page-pricing" element={<PagePricing/>}/>
      <Route path="/page-faqs" element={<PageFaqs/>}/>
      <Route path="/profile" element={<Profile/>}/>
      <Route path="/profile-setting" element={<ProfileSetting/>}/>
      <Route path="/profile-payment" element={<ProfilePayment/>}/>
      <Route path="/billing-invoice" element={<BillingInvoice/>}/>
      <Route path="/career-detail" element={<CareerDetail/>}/>
      <Route path="/career-detail/:id" element={<CareerDetail/>}/>
      <Route path="/career-apply" element={<CareerApply/>}/>
      <Route path="/blogs" element={<Blogs/>}/>
      <Route path="/blog-detail" element={<BlogDetail/>}/>
      <Route path="/blog-detail/:id" element={<BlogDetail/>}/>
      <Route path="/page-terms" element={<PageTerms/>}/>
      <Route path="/page-privacy" element={<PagePrivacy/>}/>
      <Route path="/page-comingsoon" element={<ComingSoon/>}/>
      <Route path="/page-maintenance" element={<Maintenance/>}/>
      <Route path="/page-error" element={<PageError/>}/>
      <Route path="*" element={<PageError/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/signup" element={<Signup/>}/>
      <Route path="/payment" element={<PaymentForm/>}/>
      <Route path="/forgot-password" element={<ForgotPassword/>}/>
      <Route path="/thankyou" element={<ThankYou/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/work-classic-two" element={<ClassicTwo/>}/>
      <Route path="/work-classic-three" element={<ClassicThree/>}/>
      <Route path="/work-classic-four" element={<ClassicFour/>}/>
      <Route path="/work-classic-five" element={<ClassicFive/>}/>
      <Route path="/work-classic-six" element={<ClassicSix/>}/>
      <Route path="/work-grid-two" element={<GridTwo/>}/>
      <Route path="/work-grid-three" element={<GridThree/>}/>
      <Route path="/work-grid-four" element={<GridFour/>}/>
      <Route path="/work-grid-five" element={<GridFive/>}/>
      <Route path="/work-grid-Six" element={<GridSix/>}/>
      <Route path="/work-modern-two" element={<ModernTwo/>}/>
      <Route path="/work-modern-three" element={<ModernThree/>}/>
      <Route path="/work-modern-four" element={<ModernFour/>}/>
      <Route path="/work-modern-five" element={<ModernFive/>}/>
      <Route path="/work-modern-six" element={<ModernSix/>}/>
      <Route path="/work-masonry-two" element={<MasonryTwo/>}/>
      <Route path="/work-masonry-three" element={<MasonryThree/>}/>
      <Route path="/work-masonry-four" element={<MasonryFour/>}/>
      <Route path="/work-masonry-five" element={<MasonryFive/>}/>
      <Route path="/work-masonry-six" element={<MasonrySix/>}/>
      <Route path="/work-detail" element={<WorkDetail/>}/>
    </Routes>
   
  );
}

export default App;
