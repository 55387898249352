import React from "react";
import { Link } from "react-router-dom";

import apps from '../assets/images/apps/2.png'
import tasks from '../assets/images/task1.png'

import { MdOutlineCheckCircle, FiArrowRight } from '../assets/icons/icons'

export default function Analytics(){
    return(
        <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
                <img src={tasks} className="img-fluid" alt=""/>
            </div>

            <div className="col-lg-7 col-md-6 mt-4 mt-am-0 pt-2 pt-sm-0">
                <div className="ms-lg-5">
                    <div className="section-title">
                        <h6 className="text-primary"></h6>
                        <h4 className="title mb-4">Why Choose Operation Track?
                        </h4>
                        <p className="text-muted para-desc mx-auto mb-0">Operation Track sets itself apart in the crowded field of delivery management solutions through its robust, feature-rich platform that's been rigorously tested in real-world scenarios. With extensive testing across a vast number of deliveries, our software is proven to be reliable and effective in managing even the most demanding logistics challenges. Here's why Operation Track is the right choice for your business:
                        </p>
                    
                        <ul className="list-unstyled text-muted mt-3">
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span><b>Real-World Tested:</b> Our app has been rigorously tested for over a year in real-world conditions, ensuring reliability and performance under pressure.</li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span><b>Comprehensive Feature Suite:</b> From dispatch to delivery, our tools cover every aspect of the delivery process, ensuring a seamless flow of operations.</li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span><b>Scalable Solutions:</b> Whether you're a small startup or a large enterprise, our software grows with your business, adapting to your evolving needs.</li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span><b>Enhanced Customer Engagement:</b> Our Customer Portal and feedback systems empower your customers and encourage interaction, boosting satisfaction and loyalty.</li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span><b>Optimized Operational Efficiency:</b> Automated routing, scheduling, and real-time tracking reduce costs and improve service delivery speeds.</li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span><b>Advanced Driver Management:</b> Keep your drivers informed and efficient with tools designed for better route management and performance tracking.</li>
                            <li className="mb-0 ms-0"><span className="text-primary h5 me-2"><MdOutlineCheckCircle className="align-middle"/></span><b>Client tagging and internal feedback:</b> improve delivery efficiency and driver knowledge with a delivery notes and internal feedback system that can be tagged to each client profile.</li>
                        </ul>
                        <p className="text-muted para-desc mx-auto mb-0">Choose Operation Track to leverage a delivery management system that truly understands the needs of modern logistics and delivery businesses.</p>

                        {/* <div className="mt-4">
                            <Link to="#" className="btn btn-soft-primary">Try for free <FiArrowRight className="fea icon-sm"/></Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}