import React from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-profile.jpg'
import client from '../../assets/images/client/01.jpg'

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import { FiAirplay, FiMapPin, LuUser2, FiMail, SlBookOpen, MdFormatItalic, FiGlobe, FiGift, FiSmartphone, FiArrowRight, RiGraduationCapLine, FiTag } from '../../assets/icons/icons'
import { connectionsData, educationData, experienceData, studioProject } from "../../data/data";

export default function Profile(){
    return(
        <>
        <Navbar/>
        <section className="mt-5 pt-5">
            <div className="container">
                <div className="bg-profile w-100 d-table rounded-md shadow" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
                    <div className="bg-overlay bg-soft-primary rounded-md"></div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="text-center avatar-profile">
                            <img src={client} className="rounded-circle shadow-md avatar avatar-medium" alt=""/>
                            <h4 className="mt-3">Calvin Carlo</h4>
                            <ul className="list-unstyled mb-0">
                                <li className="list-inline-item text-muted me-3 d-inline-flex align-items-center"><FiAirplay className="text-dark h5 mb-0 me-1"/> Web Developer</li>
                                <li className="list-inline-item text-muted d-inline-flex align-items-center"><FiMapPin className="text-dark h5 mb-0 me-1"/> Sydney, Australia</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="rounded shadow p-4">
                            <div className="progress-box">
                                <h5 className="title">Complete your profile</h5>
                                <div className="progress">
                                    <div className="progress-bar position-relative bg-primary" style={{width:'89%'}}>
                                        <div className="progress-value d-block text-muted h6">89%</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sidebar sticky-bar mt-4 pt-2">
                            <div className="rounded shadow p-4">
                                <h5 className="title pb-4 mb-0">Profile</h5>
                                <div className="pt-4 border-top">
                                    <div className="d-flex align-items-center">
                                        <LuUser2 className="text-primary h4 me-3"/>
                                        <div className="flex-1">
                                            <h6 className="mb-0">Name :</h6>
                                            <p className="text-muted mb-0">Calvin carlo</p>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center mt-3">
                                        <FiMail className="text-primary h4 me-3"/>
                                        <div className="flex-1">
                                            <h6 className="mb-0">Email :</h6>
                                            <p className="text-muted mb-0">calvincarlo0203@mail.com</p>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center mt-3">
                                        <SlBookOpen className="text-primary h4 me-3"/>
                                        <div className="flex-1">
                                            <h6 className="mb-0">Skills :</h6>
                                            <p className="text-muted mb-0">html, css, js, mysql</p>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center mt-3">
                                        <MdFormatItalic className="text-primary h4 me-3"/>
                                        <div className="flex-1">
                                            <h6 className="mb-0">Language :</h6>
                                            <p className="text-muted mb-0">English, Japanese, Chinese</p>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center mt-3">
                                        <FiGlobe className="text-primary h4 me-3"/>
                                        <div className="flex-1">
                                            <h6 className="mb-0">Website :</h6>
                                            <p className="text-muted mb-0">www.calvincarlo.com</p>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center mt-3">
                                        <FiGift className="text-primary h4 me-3"/>
                                        <div className="flex-1">
                                            <h6 className="mb-0">Birthday :</h6>
                                            <p className="text-muted mb-0">2nd March, 1996</p>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center mt-3">
                                        <FiMapPin className="text-primary h4 me-3"/>
                                        <div className="flex-1">
                                            <h6 className="mb-0">Location :</h6>
                                            <p className="text-muted mb-0">Sydney, Australia</p>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center mt-3">
                                        <FiSmartphone className="text-primary h4 me-3"/>
                                        <div className="flex-1">
                                            <h6 className="mb-0">Cell No :</h6>
                                            <p className="text-muted mb-0">(+12) 1254-56-4896</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5>Calvin Carlo</h5>
                        <p className="text-muted mb-0">I have started my career as a trainee and prove my self and achieve all the milestone with good guidance and reach up to the project manager. In this journey, I understand all the procedure which make me a good developer, team leader, and a project manager.</p>
                        
                        <div className="row">
                            <div className="col-12 mt-4 pt-2">
                                <h6>Connections</h6>
                                <ul className="list-unstyled mt-3 mb-0">
                                    {connectionsData.map((item,index) =>{
                                        return(
                                        <li className="list-inline-item text-center m-1" key={index}>
                                            <Link to="#" className="d-block"><img src={item.image} className="rounded-circle avatar avatar-small shadow" alt=""/></Link>
                                            <h6 className="text-muted mb-0 mt-2">{item.title}</h6>
                                        </li> 
                                        )
                                    })}
                                    <li className="list-inline-item m-1">
                                        <Link to="#" className="text-primary h6 mb-0">See More <FiArrowRight className="fea icon-sm"/></Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-6 col-12 mt-4 pt-2">
                                <h6>Education</h6>
                                {educationData.map((item, index) =>{
                                    return(
                                        <div className={item.class} key={index}>
                                            <div className="icon text-center rounded-md me-3">
                                                <h3 className="mb-0"><RiGraduationCapLine /></h3>
                                            </div>
                                            <div className="flex-1 ms-2">
                                                <h5 className="mb-0">{item.name}</h5>
                                                <p className="text-muted mb-0">{item.year}</p>
                                                <p className="text-muted mb-0">{item.location}</p>    
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="col-lg-6 col-12 mt-4 pt-2">
                                <h6>Experience</h6>
                                {experienceData.map((item, index) =>{
                                    return(
                                        <div className="d-flex features feature-danger align-items-center mt-4 p-3 rounded shadow">
                                            <div className="icon text-center rounded-md me-2">
                                                <img src={item.image} className="avatar avatar-ex-sm" alt=""/>
                                            </div>
                                            <div className="flex-1 ms-3">
                                                <h5 className="title mb-0">{item.title}</h5>
                                                <p className="text-muted mb-0">{item.year}</p>
                                                <p className="text-muted mb-0"><Link to="#" className="link">{item.name}</Link>{item.location}</p>    
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="col-12 mt-4 pt-2">
                                <h6 className="mb-0">Projects</h6>

                                <div className="row">
                                    {studioProject.map((item, index) =>{
                                        return(
                                            <div className="col-lg-6 mt-4" key={index}>
                                                <div className="card work-container work-primary bg-white border-0 rounded shadow overflow-hidden">
                                                    <img src={item.image} className="img-fluid" alt=""/>
                        
                                                    <div className="card-body content p-4">
                                                        <Link to="#" className="title text-dark h5">{item.title}</Link>
                                                        
                                                        <ul className="list-unstyled d-flex justify-content-between mb-0 mt-3">
                                                            <li><FiTag className="me-1"/><Link to="#" className="text-muted tag">{item.tag}</Link></li>
                                                            <li><Link to="#" className="text-primary">Read More <FiArrowRight className="fea icon-sm"/></Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}