import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FiArrowUp } from '../assets/icons/icons'

export default function ScrollTop(){
    let [ scrollTop, setScrollTop] = useState(false)

    useEffect(()=>{
        let scrollTop =()=>{
            setScrollTop(window.scrollY > 300)
        }
        window.addEventListener('scroll', scrollTop)
        return()=>{
            window.removeEventListener('scroll', scrollTop)
        }
    })
    let scrollToTop =()=>{
        window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          }); 
    }
    return(
        <>
        <Link to="#" onClick={()=>scrollToTop()} className="btn btn-icon btn-pills btn-primary back-to-top" id="back-to-top" style={{display: scrollTop ? 'block' : 'none'}}><FiArrowUp className="icons"/></Link>
        </>
    )
}