import React from "react";
import { Link, useParams } from "react-router-dom";

import brandImg from "../../assets/images/brand/airbnb.svg"

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import { FiMapPin, FiGlobe, LuCircleDollarSign, PiTelegramLogo, FaRegStar, FiArrowRight, LuUser2, FiAirplay, FiBriefcase, LuGraduationCap, FiCalendar } from '../../assets/icons/icons'
import { jobData } from "../../data/data";

export default function CareerDetail(){
    let params = useParams();
    let id = params.id
    let data = jobData.find((job) => job.id === parseInt(id))
    return(
        <>
        <Navbar/>

        <section className="bg-half-130 bg-light d-table w-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="row mt-5 align-items-center">
                            <div className="col-lg-5 col-md-5 col-12 d-none d-md-block">
                                <div className="rounded shadow text-center bg-white p-4">
                                    <img src={brandImg} className="avatar avatar-medium" alt=""/>
        
                                    <div className="mt-4">
                                        <h5 className="mb-0">Airbnb</h5>
                                        <ul className="list-unstyled mt-2 mb-0">
                                            <li className="text-muted d-flex justify-content-center mb-2"><FiMapPin className="h5 mb-0 me-1"/> San Francisco</li>
                                            <li className="text-muted d-flex justify-content-center"><FiGlobe className="h5 mb-0 me-1 mt-1"/> airbnb.co.in</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
        
                            <div className="col-lg-7 col-md-7 col-12">
                                <div className="section-title text-center text-md-start ms-md-4 ms-lg-5">
                                    <h6 className="text-primary">Work From Home</h6>
                                    <h4 className="title">{data?.job ? data.job :'Back-End Developer'}</h4>
                                    <p className="text-muted">Posted 18 September by <Link to="#" className="text-primary">Airbnb, Inc.</Link></p>
                                    <ul className="list-unstyled">
                                        <li className="list-inline-item text-muted small me-3 d-inline-flex align-items-center"><FiMapPin className="text-dark h6 me-1 mb-0"/> Bothell, WA, USA</li>
                                        <li className="list-inline-item text-muted small d-inline-flex align-items-center"><LuCircleDollarSign className="text-dark h6 me-1 mb-0"/>$13.00 - $18.00 per hour</li>
                                    </ul>
                                    <Link to="/career-apply" className="btn btn-soft-primary me-2 mt-1">Apply Now <PiTelegramLogo /></Link>
                                    <Link to="#" className="btn btn-outline-primary mt-1 d-inline-flex align-items-center">Sortlist <FaRegStar className="ms-1"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-7">
                        <h5>Job Description: </h5>
                        <p className="text-muted">One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others - which creates a distinct visual impression. Moreover, in Latin only words at the beginning of sentences are capitalized.</p>
                        <p className="text-muted">This means that Lorem Ipsum cannot accurately represent, for example, German, in which all nouns are capitalized. Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. If the fill text is intended to illustrate the characteristics of different typefaces.</p>
                        <p className="text-muted">It sometimes makes sense to select texts containing the various letters and symbols specific to the output language.</p>
                        
                        <h5 className="mt-4">Responsibilities and Duties: </h5>
                        <p className="text-muted">It sometimes makes sense to select texts containing the various letters and symbols specific to the output language.</p>
                        <ul className="list-unstyled">
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Participate in requirements analysis</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Write clean, scalable code using C# and .NET frameworks</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Test and deploy applications and systems</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Revise, update, refactor and debug code</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Improve existing software</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Develop documentation throughout the software development life cycle (SDLC)</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Serve as an expert on applications and provide technical support</li>
                        </ul>

                        <h5 className="mt-4">Required Experience, Skills and Qualifications: </h5>
                        <p className="text-muted">It sometimes makes sense to select texts containing the various letters and symbols specific to the output language.</p>
                        <ul className="list-unstyled mb-0">
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Proven experience as a .NET Developer or Application Developer</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>good understanding of SQL and Relational Databases, specifically Microsoft SQL Server.</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Experience designing, developing and creating RESTful web services and APIs</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Basic know how of Agile process and practices</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Good understanding of object-oriented programming.</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Good understanding of concurrent programming.</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Sound knowledge of application architecture and design.</li>
                            <li className="text-muted mt-2 ms-0"><FiArrowRight className="fea icon-sm text-primary me-2"/>Excellent problem solving and analytical skills</li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-5 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="card sidebar sticky-bar rounded shadow ms-lg-5 border-0">
                            <div className="card-body widget border-bottom">
                                <h5 className="mb-0">Job Information</h5>
                            </div>
    
                            <div className="card-body">
                                <div className="d-flex widget align-items-center">
                                    <LuUser2 className="uil uil-user me-3 h4"/>
                                    <div className="flex-1">
                                        <h5 className="widget-title mb-0">Employee Type:</h5>
                                        <p className="text-muted mb-0">Full Time</p>
                                    </div>
                                </div>

                                <div className="d-flex widget align-items-center mt-3">
                                    <FiMapPin className="uil uil-user me-3 h4"/>
                                    <div className="flex-1">
                                        <h5 className="widget-title mb-0">Location:</h5>
                                        <p className="text-muted mb-0">Beijing, China</p>
                                    </div>
                                </div>

                                <div className="d-flex widget align-items-center mt-3">
                                    <FiAirplay className="me-3 h4"/>
                                    <div className="flex-1">
                                        <h5 className="widget-title mb-0">Job Type:</h5>
                                        <p className="text-muted mb-0">{data?.job ? data.job :'Back-End Developer'}</p>
                                    </div>
                                </div>

                                <div className="d-flex widget align-items-center mt-3">
                                    <FiBriefcase className="me-3 h4"/>
                                    <div className="flex-1">
                                        <h5 className="widget-title mb-0">Experience:</h5>
                                        <p className="text-muted mb-0">+2 Year</p>
                                    </div>
                                </div>

                                <div className="d-flex widget align-items-center mt-3">
                                    <LuGraduationCap className="me-3 h4"/>
                                    <div className="flex-1">
                                        <h5 className="widget-title mb-0">Qualifications:</h5>
                                        <p className="text-muted mb-0">MSCIT</p>
                                    </div>
                                </div>

                                <div className="d-flex widget align-items-center mt-3">
                                    <LuCircleDollarSign className="me-3 h4"/>
                                    <div className="flex-1">
                                        <h5 className="widget-title mb-0">Salary:</h5>
                                        <p className="text-muted mb-0">+50k to 70k</p>
                                    </div>
                                </div>

                                <div className="d-flex widget align-items-center mt-3">
                                    <FiCalendar className="me-3 h4"/>
                                    <div className="flex-1">
                                        <h5 className="widget-title mb-0">Date posted:</h5>
                                        <p className="text-muted mb-0 mb-0">18th Sep, 2020</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}