import React,{useState} from "react";
import { Link } from "react-router-dom";

import { mesonaryPortfolio, mesonaryImage } from "../data/data";

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import { FiCamera } from '../assets/icons/icons'

import Lightbox from 'react-18-image-lightbox';
import "react-18-image-lightbox/style.css"

export default function MasonryPortfolio({container,grid,col}){
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [open, setIsOpen] = useState(false);

    const handleMovePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + mesonaryImage.length - 1) % mesonaryImage.length);
    };

    const handleMoveNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % mesonaryImage.length);
    };

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setIsOpen(true);
    };
    
    const currentImage = mesonaryImage[currentImageIndex - 1];

    const matchCategory = (category) => {
        setSelectedCategory(category);
    };

    const filteredData = selectedCategory
        ? mesonaryPortfolio.filter((item) => item.category === selectedCategory)
        : mesonaryPortfolio;
    return(
        <>
         <div className="container mb-4">
            <div className="row justify-content-center">
                <div className="col-12 filters-group-wrap">
                    <div className="filters-group">
                        <ul className="container-filter categories-filter list-inline mb-0 filter-options text-center">
                            <li className={`${selectedCategory === null ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory(null)}>All</li>
                            <li className={`${selectedCategory === 'branding' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('branding')}>Branding</li>
                            <li className={`${selectedCategory === 'designing' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('designing')}>Designing</li>
                            <li className={`${selectedCategory === 'photography' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('photography')}>Photography</li>
                            <li className={`${selectedCategory === 'development' ? "active" : ''} list-inline-item position-relative categories categories-name text-dark rounded px-1 mx-1`} onClick={() => matchCategory('development')}>Development</li>
                        </ul>
                    </div>
                </div>    
            </div>
        </div>

        <div className={container}>
            <div className="row justify-content-center">
                <div className={col}>
                    <div id="grid">
                        <ResponsiveMasonry
                            columnsCountBreakPoints={grid}
                        >
                            <Masonry>
                                {filteredData.map((item, index) =>{
                                    return(
                                        <div className="col-12 spacing picture-item" data-groups='["photography"]' key={index}>
                                            <div className="card portfolio portfolio-masonry d-grid border-0">
                                                <div className="portfolio-box position-relative rounded-2 shadow overflow-hidden">
                                                    <img src={item.image} className="img-fluid" alt=""/>  
                                                    <div className="overlay-work"></div>
                                                    <div className="content bottom-0 p-3 position-absolute">
                                                        <h6 className="tag mb-1">{item.name}</h6>
                                                        <Link to="" className="title fw-bold d-block mb-0 text-decoration-none h5">{item.title}</Link>
                                                    </div>
                                                    <div className="masonry-icon top-0 end-0 p-3 position-absolute">
                                                        <Link to="#" onClick={() => handleImageClick(item.id)} className="work-icon rounded-pill d-inline-flex lightbox">
                                                            <FiCamera className="icon"/>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                
                            </Masonry>
                        </ResponsiveMasonry>
                    {open && (
                        <Lightbox
                            mainSrc={currentImage}
                            prevSrc={mesonaryImage[(currentImageIndex + mesonaryImage.length - 1) % mesonaryImage.length]}
                            nextSrc={mesonaryImage[(currentImageIndex + 1) % mesonaryImage.length]}

                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                        />
                        )}
                    </div>
                    <div className="row mt-4 pt-2">
                        <div className="col-12">
                            <ul className="pagination justify-content-center mb-0">
                                <li className="page-item ms-0"><Link className="page-link" to="#" aria-label="Previous">Prev</Link></li>
                                <li className="page-item ms-0 active"><Link className="page-link" to="#">1</Link></li>
                                <li className="page-item ms-0"><Link className="page-link" to="#">2</Link></li>
                                <li className="page-item ms-0"><Link className="page-link" to="#">3</Link></li>
                                <li className="page-item ms-0"><Link className="page-link" to="#" aria-label="Next">Next</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}