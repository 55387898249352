import React from "react";
import { Link } from "react-router-dom";

import brandImg from "../../assets/images/brand/airbnb.svg"

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import { FiMapPin, FiGlobe, LuCircleDollarSign, FiArrowRight, FiAirplay } from '../../assets/icons/icons'

export default function CareerApply(){
    return(
        <>
        <Navbar/>

        <section className="bg-half-130 bg-light d-table w-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="row mt-5 align-items-center">
                            <div className="col-lg-5 col-md-5 col-12 d-none d-md-block">
                                <div className="rounded shadow text-center bg-white p-4">
                                    <img src={brandImg} className="avatar avatar-medium" alt=""/>
        
                                    <div className="mt-4">
                                        <h5 className="mb-0">Airbnb</h5>
                                        <ul className="list-unstyled mt-2 mb-0">
                                            <li className="text-muted d-flex justify-content-center mb-2"><FiMapPin className="h5 mb-0 me-1"/> San Francisco</li>
                                            <li className="text-muted d-flex justify-content-center"><FiGlobe className="h5 mb-0 me-1 mt-1"/> airbnb.co.in</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
        
                            <div className="col-lg-7 col-md-7 col-12">
                                <div className="section-title text-center text-md-start ms-md-4 ms-lg-5">
                                    <h6 className="text-primary">Work From Home</h6>
                                    <h4 className="title">Back-End Developer</h4>
                                    <p className="text-muted">Posted 18 September by <Link to="#" className="text-primary">Airbnb, Inc.</Link></p>
                                    <ul className="list-unstyled">
                                        <li className="list-inline-item text-muted small me-3 d-inline-flex align-items-center"><FiMapPin className="text-dark h6 me-1 mb-0"/> Bothell, WA, USA</li>
                                        <li className="list-inline-item text-muted small d-inline-flex align-items-center"><LuCircleDollarSign className="text-dark h6 me-1 mb-0"/>$13.00 - $18.00 per hour</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-7">
                        <div className="card custom-form border-0">
                            <div className="card-body p-0">
                                <form className="rounded shadow p-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Your Name :<span className="text-danger">*</span></label>
                                                <input name="name" id="name" type="text" className="form-control" placeholder="First Name :"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Your Email :<span className="text-danger">*</span></label>
                                                <input name="email" id="email" type="email" className="form-control" placeholder="Your email :"/>
                                            </div> 
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Your Phone no. :<span className="text-danger">*</span></label>
                                                <input name="number" id="number" type="number" className="form-control" placeholder="Your phone no. :"/>
                                            </div> 
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Job Title :</label>
                                                <input name="subject" id="subject" className="form-control" placeholder="Title :"/>
                                            </div>                                                                               
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Types of jobs :</label>
                                                <select className="form-select form-control" id="Sortbylist-Shop">
                                                    <option>All Jobs</option>
                                                    <option>Full Time</option>
                                                    <option>Half Time</option>
                                                    <option>Remote</option>
                                                    <option>In Office</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label">Description :</label>
                                                <textarea name="comments" id="comments" rows="4" className="form-control" placeholder="Describe the job :"></textarea>
                                            </div>
                                        </div>                                    
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label">Upload Your Cv / Resume :</label>
                                                <input type="file" className="form-control-file" id="fileupload"/>
                                            </div>                                                                               
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">I Accept <Link to="#" className="text-primary">Terms And Condition</Link></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <input type="submit" id="submit" name="send" className="submitBnt btn btn-primary" value="Apply Now"/>
                                        </div>
                                    </div>
                                </form>
                            </div> 
                        </div>

                        <div className="row align-items-end mb-4 pb-2 mt-5">
                            <div className="col-md-8">
                                <div className="section-title text-center text-md-start">
                                    <h4 className="title mb-4">Related Jobs</h4>
                                    <p className="text-muted mb-0 para-desc">Start work with Leaping. Build responsive, mobile-first projects on the web with the world's most popular front-end component library.</p>
                                </div>
                            </div>
        
                            <div className="col-md-4 mt-4 mt-sm-0 d-none d-md-block">
                                <div className="text-center text-md-end">
                                    <Link to="/index-career" className="text-primary">View more Jobs <FiArrowRight className="fea icon-sm"/></Link>
                                </div>
                            </div>
                        </div>
        
                        <div className="row">                    
                            <div className="col-md-6 col-12 mt-4 pt-2">
                                <div className="card border-0 bg-light rounded shadow">
                                    <div className="card-body p-4">
                                        <span className="badge rounded-pill bg-primary float-md-end mb-3 mb-sm-0">Remote</span>
                                        <h5>Front-end Developer</h5>
                                        <div className="mt-3">
                                            <span className="text-muted d-flex align-items-center"><FiAirplay className="align-middle text-primary h5 mb-0 me-1"/> <Link to="https://1.envato.market/hostick-template" target="_blank" className="text-muted">Hostick pvt. ltd.</Link></span>
                                            <span className="text-muted d-flex align-items-center"><FiMapPin className="align-middle text-primary h5 mb-0 me-1"/> Australia</span>
                                        </div>
                                        
                                        <div className="mt-3">
                                            <Link to="/career-detail" className="btn btn-primary">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6 col-12 mt-4 pt-2">
                                <div className="card border-0 bg-light rounded shadow">
                                    <div className="card-body p-4">
                                        <span className="badge rounded-pill bg-primary float-md-end mb-3 mb-sm-0">Contract</span>
                                        <h5>Web Developer</h5>
                                        <div className="mt-3">
                                            <span className="text-muted d-flex align-items-center"><FiAirplay className="align-middle text-primary h5 mb-0 me-1"/> <Link to="#" className="text-muted">Landflow pvt. ltd.</Link></span>
                                            <span className="text-muted d-flex align-items-center"><FiMapPin className="align-middle text-primary h5 mb-0 me-1"/> USA</span>
                                        </div>
                                        
                                        <div className="mt-3">
                                            <Link to="/career-detail" className="btn btn-primary">View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}