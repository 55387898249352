import React from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-lines-one.png'
import bg2 from '../../assets/images/bg/bg-invoice.png'
import logo from '../../assets/images/logo-dark.png'
import Navbar from "../../components/navbar";

import { LuUser2, FiMapPin, FiMail, FiPhone, MdOutlineLocalPrintshop } from '../../assets/icons/icons'
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

export default function BillingInvoice(){
    return(
        <>
        <Navbar/>
        <section className="bg-half-170 bg-light" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-lg-10">
                        <div className="card bg-white border-0 rounded shadow" style={{backgroundImage:`url(${bg2})`, backgroundPosition:'center'}}>
                            <div className="p-4">
                                <div className="row mb-4">
                                    <div className="col-lg-8 col-md-7">
                                        <img src={logo} height="28" alt=""/>
                                        <h5 className="mt-4">Address :</h5>
                                        <h6 className="text-muted mb-0">1419 Riverwood Drive, <br/>Redding, CA 96001</h6>
                                    </div>

                                    <div className="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <ul className="list-unstyled">
                                            <li className="d-flex ms-0">
                                                <h6 className="mb-0 text-muted">Invoice no. : </h6>
                                                <h6 className="mb-0 text-dark">&nbsp;&nbsp;#54638990jnn</h6>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                                <h6 className="mb-0 text-muted">Email : </h6>
                                                <h6 className="mb-0">&nbsp;&nbsp;<Link to="mailto:contact@example.com" className="text-dark">info@landflow.com</Link></h6>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                                <h6 className="mb-0 text-muted">Phone : </h6>
                                                <h6 className="mb-0">&nbsp;&nbsp;<Link to="tel:+152534-468-854" className="text-dark">(+12) 1546-456-856</Link></h6>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                                <h6 className="mb-0 text-muted">Website : </h6>
                                                <h6 className="mb-0">&nbsp;&nbsp;<Link to="#" className="text-dark">www.landflow.com</Link></h6>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="pt-4 border-top">
                                    <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                            <h4 className="text-muted fw-bold">Invoice</h4>
                                            <h6>Website Design & Development <span className="badge rounded-pill bg-soft-success ms-2">Paid</span></h6>
                                        </div>
    
                                        <div className="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <ul className="list-unstyled">
                                                <li className="d-flex mt-2 ms-0">
                                                    <h6 className="mb-0 text-muted">Issue Dt. : </h6>
                                                    <h6 className="mb-0 text-dark">&nbsp;&nbsp;25th Sep. 2020</h6>
                                                </li>
                                                <li className="d-flex mt-2 ms-0">
                                                    <h6 className="mb-0 text-muted">Due Dt. : </h6>
                                                    <h6 className="mb-0 text-dark">&nbsp;&nbsp;11th Oct. 2020</h6>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-8 col-md-7 mt-4 pt-2">
                                            <h5>From :</h5>
                                            <ul className="list-unstyled mb-0">
                                                <li className="d-flex ms-0 align-items-start">
                                                    <LuUser2 className="me-2 h6 mb-0 mt-1"/>
                                                    <h6 className="text-muted">Calvin Carlo</h6>
                                                </li>

                                                <li className="d-flex ms-0 align-items-start">
                                                    <FiMapPin className="me-2 h6 mb-0 mt-1"/>
                                                    <h6 className="text-muted">1419 Riverwood Drive, <br/>Redding, CA 96001</h6>
                                                </li>

                                                <li className="d-flex ms-0 align-items-start">
                                                    <FiMail className="me-2 h6 mb-0 mt-1"/>
                                                    <h6 className="text-muted">calvin.carlo@domain.com</h6>
                                                </li>

                                                <li className="d-flex ms-0 align-items-start">
                                                    <FiPhone className="me-2 h6 mb-0 mt-1"/>
                                                    <h6 className="text-muted">(+12) 1546-456-856</h6>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-4 col-md-5 mt-4 pt-2">
                                            <h5>Bill to :</h5>
                                            <ul className="list-unstyled mb-0">
                                                <li className="d-flex ms-0 align-items-start">
                                                    <LuUser2 className="me-2 h6 mb-0 mt-1"/>
                                                    <h6 className="text-muted">ABC pvt ltd.</h6>
                                                </li>

                                                <li className="d-flex ms-0 align-items-start">
                                                    <FiMapPin className="me-2 h6 mb-0 mt-1"/>
                                                    <h6 className="text-muted">1419 Riverwood Drive, <br/>Redding, CA 96001</h6>
                                                </li>

                                                <li className="d-flex ms-0 align-items-start">
                                                    <FiMail className="me-2 h6 mb-0 mt-1"/>
                                                    <h6 className="text-muted">calvin.carlo@domain.com</h6>
                                                </li>

                                                <li className="d-flex ms-0 align-items-start">
                                                    <FiPhone className="me-2 h6 mb-0 mt-1"/>
                                                    <h6 className="text-muted">(+12) 1546-456-856</h6>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div className="invoice-table pb-4">
                                        <div className="table-responsive shadow rounded mt-4">
                                            <table className="table table-center invoice-tb mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="border-bottom text-start" style={{minWidth:'60px'}}>No.</th>
                                                        <th scope="col" className="border-bottom text-start" style={{minWidth:'220px'}}>Item</th>
                                                        <th scope="col" className="border-bottom text-center" style={{minWidth:'60px'}}>Qty</th>
                                                        <th scope="col" className="border-bottom" style={{minWidth:'130px'}}>Rate</th>
                                                        <th scope="col" className="border-bottom" style={{minWidth:'130px'}}>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" className="text-start">1</th>
                                                        <td className="text-start">Inclusive Insights</td>
                                                        <td className="text-center">2</td>
                                                        <td>$ 2600</td>
                                                        <td>$ 5200</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row" className="text-start">2</th>
                                                        <td className="text-start">Handy Guide</td>
                                                        <td className="text-center">1</td>
                                                        <td>$ 3660</td>
                                                        <td>$ 3660</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row" className="text-start">3</th>
                                                        <td className="text-start">Premiere Product</td>
                                                        <td className="text-center">3</td>
                                                        <td>$ 4580</td>
                                                        <td>$ 13740</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-5 ms-auto">
                                                <ul className="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
                                                    <li className="text-muted d-flex justify-content-between ms-0">Subtotal :<span>$ 22600</span></li>
                                                    <li className="text-muted d-flex justify-content-between ms-0">Taxes :<span> 0</span></li>
                                                    <li className="d-flex justify-content-between ms-0">Total :<span>$ 22600</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border-top pt-4">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="text-sm-start text-muted text-center">
                                                    <h6 className="mb-0">Customer Services : <Link to="tel:+152534-468-854" className="text-warning">(+12) 1546-456-856</Link></h6>
                                                </div>
                                            </div>
        
                                            <div className="col-sm-6">
                                                <div className="text-sm-end text-muted text-center">
                                                    <h6 className="mb-0"><Link to="#" className="text-primary">Terms & Conditions</Link></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-end mt-4 pt-2">
                            <Link to="#" onClick={()=>window.print()} className="btn btn-soft-primary d-print-none"><MdOutlineLocalPrintshop /> Print</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}