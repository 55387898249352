import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";

import blog from '../../assets/images/blog/single.jpg'
import blog1 from '../../assets/images/blog/1.jpg'
import blog2 from '../../assets/images/blog/2.jpg'
import client from '../../assets/images/client/05.jpg'

import Navbar from "../../components/navbar";
import { blogData } from "../../data/data";

import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss'

import { FiCalendar, FiClock, LuUser2, FiArrowRight } from '../../assets/icons/icons'
import FooterTopTwo from '../../components/footerTopTwo';
import Footer from '../../components/footer';
import ScrollTop from '../../components/scrollTop';

export default function BlogDetail(){

    let [isOpen, setOpen] = useState(false);
    let params = useParams();
    let id = params.id
    let data = blogData.find((blog) => blog.id === parseInt(id))
    return(
        <>
        <Navbar/>
        <section className="bg-half-170 d-table w-100 bg-light">
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-lg-10">
                        <div className="section-title">
                            <ul className="list-unstyled mb-3">
                                <li className="list-inline-item text-muted d-inline-flex align-items-center me-3"><FiCalendar className="text-dark h6 me-1 mb-0"/>{data?.date ? data.date : '19th June, 2023'}</li>
                                <li className="list-inline-item text-muted d-inline-flex align-items-center me-3"><FiClock className="text-dark h6 me-1 mb-0"/>15 min read</li>
                                <li className="list-inline-item text-muted d-inline-flex align-items-center"><LuUser2 className="text-dark h6 me-1 mb-0"/>Cristina Murphy</li>
                            </ul>

                            <h2 className="mb-4">{data?.title ? data.title : 'How to Perform an SEO Audit Step-by-Step Guide'}</h2>
                            <p className="text-muted mb-0">This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics when writing melodies in order to have a 'ready-made' text to sing with the melody. Dummy texts have been in use by typesetters since the 16th century.</p>
                        </div>

                        <div className="position-relative mt-4 pt-2">
                            <img src={data?.image ? data.image : blog} className="rounded img-fluid mx-auto d-block" alt=""/>
                            <div className="play-icon">
                                <Link to="#"  onClick={() => setOpen(true)} className="lightbox play-btn video-play-icon">
                                    <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                                </Link>
                            </div>

                            <ModalVideo
                                channel="youtube"
                                youtube={{ mute: 0, autoplay: 0 }}
                                isOpen={isOpen}
                                videoId="yba7hPeTSjk"
                                onClose={() => setOpen(false)} 
                            />
                        </div>

                        <div className="row align-items-center mt-4">
                            <div className="col-lg-5 col-md-6 mt-4 pt-2">
                                <div className="d-md-flex text-center text-md-start align-items-center">
                                    <img src={client} className="avatar avatar-medium rounded-circle" alt=""/>
                                    <div className="ms-md-4 mt-4 mt-sm-0">
                                        <Link to="#" className="h5 text-dark">Cristina Murphy</Link>
                                        <p className="text-muted mb-0">via Linkedin</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-6 mt-4 pt-2">
                                <p className="h5 text-muted text-center text-md-start fw-normal">" There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. "</p>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-6 mt-4 pt-2">
                                <img src={blog1} className="img-fluid rounded" alt=""/>
                            </div>

                            <div className="col-md-6 mt-4 pt-2">
                                <img src={blog2} className="img-fluid rounded" alt=""/>
                            </div>
                        </div>

                        <p className="text-muted mt-5">This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics when writing melodies in order to have a 'ready-made' text to sing with the melody. Dummy texts have been in use by typesetters since the 16th century.</p>
                        <p className="text-muted">According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero in 45 BC. Allegedly, a Latin scholar established the origin of the text by compiling all the instances of the unusual word 'consectetur' he could find and eventually recognized it as a passage from 'De finibus bonorum et malorum' (On the extremes of Good and Evil) by Cicero, a book that was very popular in the Middle Ages: "Neque porro quisquam est, qui dolorem ipsum, quia dolor sit, amet, consectetur, adipisci velit... " (Approximately: "Nor is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain..."). A typical Lorem Ipsum text goes like this: "Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim ...".</p>
                    </div>
                </div>
            </div>
            
            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-4">Related Blogs or News</h4>
                            <p className="text-muted para-desc mx-auto mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {blogData.slice(0,3).map((item, index) =>{
                            return(
                            <div className="col-lg-4 col-md-6 mt-4 pt-2" key={index}>
                                <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                                    <div className="position-relative overflow-hidden">
                                        <img src={item.image} className="img-fluid" alt=""/>
                                        <Link to="#" className="tag py-1 px-4 d-inline-block h6 mb-0 text-white rounded-lg">{item.tag}</Link>
                                    </div>
                                    <div className="card-body p-4">
                                        <ul className="list-unstyled mb-2">
                                            <li className="list-inline-item text-muted small me-3 d-inline-flex align-items-center"><FiCalendar className="text-dark h6 me-1 mb-0"/>{item.date}</li>
                                            <li className="list-inline-item text-muted small d-inline-flex align-items-center"><FiClock className="text-dark h6 me-1 mb-0"/>{item.time}</li>
                                        </ul>
                                        <Link to="#" className="text-dark title h5">{item.title}</Link>
                                        
                                        <div className="mt-2">
                                            <Link to="#" className="link">Read more <FiArrowRight className="fea icon-sm"/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                </div>
            </div>
        </section>

        <FooterTopTwo/>
        <Footer/>
        <ScrollTop/>
        </>
    )
}