import React from "react";

import { FiHelpCircle } from '../assets/icons/icons'

export default function HelpCenter(){
    return(
        <div className="row">
            <div className="col-md-6 col-12">
                <div className="d-flex">
                    <FiHelpCircle className="fea icon-ex-md text-primary me-2 mt-1"/>
                    <div className="flex-1">
                        <h5 className="mt-0">How our <span className="text-primary">Operation Track work?</span></h5>
                        <p className="answer text-muted mb-0">Operation Track is a convenient delivery app that connects you with local businesses and delivery partners. Simply download the app, browse through the available options, place your order, and track your delivery in real-time.</p>
                    </div>
                </div>
            </div>
            
            <div className="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="d-flex">
                    <FiHelpCircle className="fea icon-ex-md text-primary me-2 mt-1"/>
                    <div className="flex-1">
                        <h5 className="mt-0">  How do I track my delivery?</h5>
                        <p className="answer text-muted mb-0">Once your order is placed, you can track your delivery in real-time through the app. You'll receive updates on the status of your order and the estimated time of arrival.</p>
                    </div>
                </div>
            </div>
            
            <div className="col-md-6 col-12 mt-4 pt-2">
                <div className="d-flex">
                    <FiHelpCircle className="fea icon-ex-md text-primary me-2 mt-1"/>
                    <div className="flex-1">
                        <h5 className="mt-0"> What are the delivery fees?</h5>
                        <p className="answer text-muted mb-0">Delivery fees may vary depending on the distance, time of day, and other factors. The app will display the delivery fee before you place your order.</p>
                    </div>
                </div>
            </div>
            
            <div className="col-md-6 col-12 mt-4 pt-2">
                <div className="d-flex">
                    <FiHelpCircle className="fea icon-ex-md text-primary me-2 mt-1"/>
                    <div className="flex-1">
                        <h5 className="mt-0"> Is <span className="text-primary">Operation Track</span> What if I have dietary restrictions or special requests?</h5>
                        <p className="answer text-muted mb-0">You can specify any dietary restrictions or special requests in the order notes section during checkout. Our delivery partners will do their best to accommodate your preferences.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}