import { FiAirplay, PiBrowsersBold, FiEye, FiHeart, MdOutlineMailLock, FaRegEdit, FiLayers, TbLockAccess, FiClock, FaChartLine, LuShieldCheck, TbBolt, FiStopCircle, BsPentagon, FiMonitor, FiCode, FiBold, FiSettings, FiUserCheck, FiFeather, BsPencil, FaRegHospital, BiBookReader, SiSimpleanalytics, LiaFileInvoiceDollarSolid, LuCircleDollarSign,FaRegChartBar, TbCoinBitcoin, FiClipboard, PiMoneyBold, AiOutlineEuro, MdOutlineLocalAtm, FaRegCreditCard, LuUser2, FiGlobe,TbUsersGroup, LuLink, MdOutlinePayments, LuSearch, LuClipboardList, FiSmartphone, FiAperture, RiImageAddFill, FiSmile, FiAward, FiCoffee, FiThumbsUp, FaUniversity, AiFillGold, FaRegBuilding, BsShieldPlus, VscServer, TbCloudHeart, LuMailCheck, LuUsers2, LiaTachometerAltSolid, FaRegDotCircle, LiaWeightSolid, TbChartPie4, RiCrosshair2Fill, SlBookOpen, PiHamburgerBold, PiTreePalm, PiPackage, AiFillMedicineBox, FiGift, PiDog, MdSupervisedUserCircle, GrUserAdmin, CiDeliveryTruck, GrDeliver, CgDrive, FaSignature, BsMapFill, GrSchedule, FcSurvey} from '../assets/icons/icons'

import company1 from '../assets/images/client/amazon.svg'
import company2 from '../assets/images/client/google.svg'
import company3 from '../assets/images/client/lenovo.svg'
import company4 from '../assets/images/client/paypal.svg'
import company5 from '../assets/images/client/shopify.svg'
import company6 from '../assets/images/client/spotify.svg'
import company7 from '../assets/images/client/facebook.svg'
import company8 from '../assets/images/client/instagram.svg'

import brand1 from '../assets/images/brand/apple.svg'
import brand2 from '../assets/images/brand/black-berry.svg'
import brand3 from '../assets/images/brand/swiggy.svg'
import brand4 from '../assets/images/brand/pingdom.svg'
import brand5 from '../assets/images/brand/swiggy.svg'
import brand6 from '../assets/images/brand/volkswagen.svg'

import client1 from '../assets/images/client/01.jpg'
import client2 from '../assets/images/client/02.jpg'
import client3 from '../assets/images/client/03.jpg'
import client4 from '../assets/images/client/04.jpg'
import client5 from '../assets/images/client/05.jpg'
import client6 from '../assets/images/client/06.jpg'
import client7 from '../assets/images/client/07.jpg'
import client8 from '../assets/images/client/08.jpg'

import blog1 from '../assets/images/blog/1.jpg'
import blog2 from '../assets/images/blog/2.jpg'
import blog3 from '../assets/images/blog/3.jpg'
import blog4 from '../assets/images/blog/4.jpg'
import blog5 from '../assets/images/blog/5.jpg'
import blog6 from '../assets/images/blog/6.jpg'
import blog7 from '../assets/images/blog/7.jpg'
import blog8 from '../assets/images/blog/8.jpg'
import blog9 from '../assets/images/blog/9.jpg'
import blog10 from '../assets/images/blog/10.jpg'

import about1 from '../assets/images/about/1.jpg'
import about2 from '../assets/images/about/2.jpg'
import about3 from '../assets/images/about/3.jpg'
import about4 from '../assets/images/about/4.jpg'
import about5 from '../assets/images/about/5.jpg'
import about6 from '../assets/images/about/6.jpg'
import about7 from '../assets/images/about/7.jpg'
import about8 from '../assets/images/about/8.jpg'

import work1 from '../assets/images/work/1.jpg'
import work2 from '../assets/images/work/2.jpg'
import work3 from '../assets/images/work/3.jpg'
import work4 from '../assets/images/work/4.jpg'
import work5 from '../assets/images/work/5.jpg'
import work6 from '../assets/images/work/6.jpg'
import work7 from '../assets/images/work/7.jpg'
import work8 from '../assets/images/work/8.jpg'
import work9 from '../assets/images/work/9.jpg'
import work10 from '../assets/images/work/10.jpg'
import work11 from '../assets/images/work/11.jpg'
import work12 from '../assets/images/work/12.jpg'
import work13 from '../assets/images/work/Untitled-1.jpg'
import work14 from '../assets/images/work/Untitled-2.jpg'
import work15 from '../assets/images/work/Untitled-3.jpg'
import work16 from '../assets/images/work/Untitled-4.jpg'
import work17 from '../assets/images/work/Untitled-5.jpg'

import work01 from '../assets/images/work/landrick.png'
import work02 from '../assets/images/work/doctris.png'
import work03 from '../assets/images/work/cristino.png'
import work04 from '../assets/images/work/appgen.png'

import seo1 from '../assets/images/seo/1.jpg'
import seo2 from '../assets/images/seo/2.jpg'
import seo3 from '../assets/images/seo/3.jpg'
import seo4 from '../assets/images/seo/4.jpg'
import seo5 from '../assets/images/seo/5.jpg'
import seo6 from '../assets/images/seo/6.jpg'
import seo7 from '../assets/images/seo/7.jpg'

import sponsor1 from '../assets/images/brand/airbnb.svg'
import sponsor2 from '../assets/images/brand/apple.svg'
import sponsor3 from '../assets/images/brand/swiggy.svg'
import sponsor4 from '../assets/images/brand/black-berry.svg'
import sponsor5 from '../assets/images/brand/black-berry.svg'
import sponsor6 from '../assets/images/brand/volkswagen.svg'
import sponsor7 from '../assets/images/brand/airbnb.svg'
import sponsor8 from '../assets/images/brand/swiggy.svg'
import sponsor9 from '../assets/images/brand/pingdom.svg'
import sponsor10 from '../assets/images/brand/swiggy.svg'



export const companyLogoImg = [company1, company2, company3, company4, company5, company6, company7, company8]

export const principles = [
    {
        icon:FiAirplay,
        title: 'Responsive Design',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon:PiBrowsersBold,
        title: 'Browser Compatibility',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon:FiEye,
        title: 'Retina Ready',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon:FiHeart,
        title: 'Free Icons',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
]

export const pricing = [
    {
        title: 'Free',
        amount: '0',
        desc: 'This is required when, for example, the final text is not yet available. Dummy text is also known as fill text.',
        btnText: 'Free',
        btnClass: 'btn btn-outline-primary'
    },
    {
        title: 'Standard',
        amount: '19',
        desc: 'This is required when, for example, the final text is not yet available. Dummy text is also known as fill text.',
        btnText: 'Buy Now',
        btnClass: 'btn btn-soft-primary'
    },
    {
        title: 'Business',
        amount: '49',
        desc: 'This is required when, for example, the final text is not yet available. Dummy text is also known as fill text.',
        btnText: 'Buy Now',
        btnClass: 'btn btn-primary'
    },
]

export const appFeature = [
    {
        icon: MdSupervisedUserCircle,
        title: 'Customer Portal',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: GrUserAdmin,
        title: 'Admin App',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: CiDeliveryTruck,
        title: 'Delivery Tracking',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: GrDeliver,
        title: 'Dispatch Management',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: CgDrive,
        title: 'Driver ETA',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: FiLayers,
        title: 'Driver Management',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: FaSignature,
        title: 'Mobile Signature Capture',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: BsMapFill,
        title: 'Routing',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: GrSchedule,
        title: 'Scheduling',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: FcSurvey,
        title: 'Surveys & Feedback',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
]

export const keyFeature = [
    {
        icon: TbLockAccess,
        title: '',
        desc: 'Organized and efficient: organize tasks and assign tasks in a way that best suits your needs'
    },
    {
        icon: FiClock,
        title: '',
        desc: 'Live Tracking: Real-time tracking of your fleet anywhere and visible on a map'
    },
    {
        icon: FaChartLine,
        title: '',
        desc: 'Activity Statistics: Use data collected from tasks to view reports and make decisions to improve efficiency'
    },
    {
        icon: LuShieldCheck,
        title: '',
        desc: 'Route optimization: Gain efficiency with our powerful route optimization solution and options such as fuel-efficiency optimization'
    },
    {
        icon: TbBolt,
        title: '',
        desc: 'Auto-Assign: Optimize your delivery operations with our Auto-Assign feature.'
    },
    {
        icon: FiStopCircle,
        title: '',
        desc: 'Effortless Driver Onboarding: Our intuitive UI/UX ensures that drivers can quickly and easily master the app in just a few minutes.'
    },
    {
        icon: FiStopCircle,
        title: '',
        desc: 'Budget that works: Our pricing model scales with your business, ensuring you only pay for what you need. '
    },
    {
        icon: FiStopCircle,
        title: '',
        desc: 'Search and Filter: Easily locate specific deliveries, drivers, or routes with our advanced search and filter options.'
    },
]

export const priceTwo = [
    {
        title: 'Trial',
        amount: '$0.00',
        time: 'Per Month',
        amountMonth: '$0.00',
        timeMonth: 'Per Month',
        amountYear: '$0.00',
        timeYear: 'Per Year',
        features : [
            '10 tasks / deliveries', 
            '2 driver',
             '2 dispatcher',
              '1 admin', 
            ],
        desc:'Better for growing businesses that want more customers.'
    },
    {
        title: 'Basic',
        amount: '$150.00',
        time: 'Per Month',
        amountMonth: '$150.00',
        timeMonth: 'Per Month',
        amountYear: '$1650.00',
        timeYear: 'Per Year',
        features : [
            'Photo proof of delivery', 
            'Delivery status',
             'Email Support',
              'Live Driver Tracking', 
              'Import and Export Deliveries', 
              '1000 tasks monthly (additional tasks over limit to be charged by .15cents/delivery)              ', 
              '5 drivers (additional driver over limit to be charged by $25/seat/monthly)', 
              '2 dispatchers (additional driver over limit to be charged by $25/seat/monthly)              ', 
              '2 admins (additional driver over limit to be charged by $25/seat/monthly)', 
              '1 year historical analytics', 
              'Custom messaging', 
              'Text messaging: Charge separate in arears', 
            ],
                    desc:'Better for growing businesses that want more customers.'
    },
    {
        title: 'Intermediate',
        amount: '$9.00',
        time: 'Per Month',
        amountMonth: '$300.00',
        timeMonth: 'Per Month',
        amountYear: '$3300.00',
        timeYear: 'Per Year',
        features : [
            'Everything in Basic +            ', 
            '3000 tasks monthly (additional tasks over limit to be charged by .15cents/delivery)            ',
             '20 drivers (additional driver over limit to be charged by $25/seat/month)',
              '4 dispatchers (additional driver over limit to be charged by $25/seat/month)', 
              '2 admins (additional driver over limit to be charged by $25/seat//month)', 
              'ETA              ', 
              'Basic Route optimization', 
              '1 year historical analytics', 
              'Custom messaging', 
              'Text messaging: Charge separate in arears', 
            ],     
             desc:'Better for growing businesses that want more customers.'
    },
    {
        title: 'Advance',
        amount: '$9.00',
        time: 'Per Month',
        amountMonth: '$450.00',
        timeMonth: 'Per Month',
        amountYear: '$4500.00',
        timeYear: 'Per Year',
        features : [
            'Everything in Intermediate +', 
            '5000 tasks monthly (additional tasks over limit to be charged by .15cents/delivery)',
             'Task completion customization(controlling proof of delivery, task ordering, age verification)             ',
              '50 drivers (additional driver over limit to be charged by $25/seat/month)', 
              '6 dispatchers accounts (additional driver over limit to be charged by $25/seat//month)', 
              '2 admins (additional driver over limit to be charged by $25/seat/monthly)', 
              'Advance Route optimization', 
              '7 year historical analytics', 
              'Custom messaging', 
              'Text messaging: Charge separate in arears', 
            ],     
            desc:'Better for growing businesses that want more customers.'
    },
    
]

export const featureData = [
    {
        icon: FiAirplay,
        title: 'Responsive Design',
        desc: 'Composed in a pseudo-Latin language which more or less corresponds.'
    },
    {
        icon: TbBolt,
        title: 'Unlimited Power',
        desc: 'Composed in a pseudo-Latin language which more or less corresponds.'
    },
    {
        icon: BsPentagon,
        title: 'Professional Design',
        desc: 'Composed in a pseudo-Latin language which more or less corresponds.'
    },
]
export const featureTwo = [
    {
        icon: FiMonitor,
        title: 'Fully Responsive'
    },
    {
        icon: FiHeart,
        title: 'Browser Compatibility'
    },
    {
        icon: FiEye,
        title: 'Retina Ready'
    },
    {
        icon: FiCode,
        title: 'Built With SASS'
    },
    {
        icon: FiBold,
        title: 'Latest Bootstrap'
    },
    {
        icon: FiSettings,
        title: 'Easy to customize'
    },
    {
        icon: FiUserCheck,
        title: 'W3c Valid Code'
    },
    {
        icon: FiFeather,
        title: 'Feather Icons'
    },
]
export const categories = [
    {
        icon: BsPencil,
        title: 'Design & Art',
        name: '7 Opening'
    },
    {
        icon: FiAirplay,
        title: 'IT Engineer',
        name: '1 Opening'
    },
    {
        icon: FaRegHospital,
        title: 'Health Care',
        name: '4 Opening'
    },
    {
        icon: BiBookReader,
        title: 'Teaching',
        name: '2 Opening'
    },
    {
        icon: SiSimpleanalytics,
        title: 'Management',
        name: '8 Opening'
    },
    {
        icon: LiaFileInvoiceDollarSolid,
        title: 'Marketing',
        name: '5 Opening'
    },
    {
        icon: LuCircleDollarSign,
        title: 'Sales',
        name: '2 Opening'
    },
    {
        icon: FaRegChartBar,
        title: 'Banking',
        name: '6 Opening'
    },
]
export const featuredCompany = [
    {
        image: brand1,
        title: 'Apple',
        location: 'New Mexico, USA',
        vacancy: '2 Vacancy'
    },
    {
        image: brand2,
        title: 'Black Berry',
        location: 'New Mexico, USA',
        vacancy: '4 Vacancy'
    },
    {
        image: brand3,
        title: 'Boomerang',
        location: 'New Mexico, USA',
        vacancy: '1 Vacancy'
    },
    {
        image: brand4,
        title: 'Pingdom',
        location: 'New Mexico, USA',
        vacancy: '7 Vacancy'
    },
    {
        image: brand5,
        title: 'Swiggy',
        location: 'New Mexico, USA',
        vacancy: '4 Vacancy'
    },
    {
        image: brand6,
        title: 'Valkswagon',
        location: 'New Mexico, USA',
        vacancy: '3 Vacancy'
    },
]
export const companyAbout = [
    {
        number: '3640',
        title: 'Visitor'
    },
    {
        number: '40',
        title: 'Startups'
    },
    {
        number: '56',
        title: 'Mentors'
    },
    {
        number: '12',
        title: 'Countries'
    },
    {
        number: '25',
        title: 'Stages'
    },
]
export const jobData = [
    {
        id:1,
        job: 'UX Designer',
        location: 'New York'
    },
    {
        id:2,
        job: 'Web Developer',
        location: 'New York'
    },
    {
        id:3,
        job: 'UI Designer',
        location: 'New York'
    },
    {
        id:4,
        job: 'Web Designer',
        location: 'New York'
    },
    {
        id:5,
        job: 'Android Developer',
        location: 'New York'
    },
    {
        id:6,
        job: 'Senior Back-end Developer',
        location: 'New York'
    },
    {
        id:7,
        job: 'Front-end Developer',
        location: 'New York'
    },
]
export const clientData = [
    {
        image: client1,
        name: 'Thomas Israel',
        title: 'C.E.O',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century. "'
    },
    {
        image: client2,
        name: 'Carl Oliver',
        title: 'P.A',
        desc: '" The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewers attention from the layout.  "'
    },
    {
        image: client3,
        name: 'Barbara McIntosh',
        title: 'M.D',
        desc: '" There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories. "'
    },
    {
        image: client4,
        name: 'Christa Smith',
        title: 'Manager',
        desc: '" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero in 45 BC. Allegedly, a Latin scholar established the origin of the text by compiling all the instances of the unusual word consectetur he could find "'
    },
    {
        image: client5,
        name: 'Dean Tolle',
        title: 'Developer',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century. "'
    },
    {
        image: client6,
        name: 'Jill Webb',
        title: 'Designer',
        desc: '" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century. "'
    },
]
export const blogData = [
    {
        id:1,
        image: blog1,
        title: 'A place where technology meets craftsmanship',
        date: '13th Sep 2023',
        time: '5 min read',
        tag: 'Software'
    },
    {
        id:2,
        image: blog2,
        title: 'Talisker bay on the isle of skye in scotland',
        date: '29th Nov 2023',
        time: '5 min read',
        tag: 'Designer'
    },
    {
        id:3,
        image: blog3,
        title: 'A beginner’s guide to customer journey maps',
        date: '29th Dec 2023',
        time: '5 min read',
        tag: 'Developer'
    },
    {
        id:4,
        image: blog4,
        title: 'A place where technology meets craftsmanship',
        date: '13th March 2023',
        time: '5 min read',
        tag: 'Software'
    },
    {
        id:5,
        image: blog5,
        title: 'Talisker bay on the isle of skye in scotland',
        date: '5th May 2023',
        time: '5 min read',
        tag: 'Designer'
    },
    {
        id:6,
        image: blog6,
        title: 'A beginner’s guide to customer journey maps',
        date: '19th June 2023',
        time: '5 min read',
        tag: 'Developer'
    },
    {
        id:7,
        image: blog7,
        title: 'A place where technology meets craftsmanship',
        date: '20th June 2023',
        time: '5 min read',
        tag: 'Software'
    },
    {
        id:8,
        image: blog8,
        title: 'Talisker bay on the isle of skye in scotland',
        date: '31st Aug 2023',
        time: '5 min read',
        tag: 'Designer'
    },
    {
        id:9,
        image: blog9,
        title: 'A beginner’s guide to customer journey maps',
        date: '1st Sep 2023',
        time: '5 min read',
        tag: 'Developer'
    },
    {
        id:10,
        image: blog10,
        title: 'A beginner’s guide to customer journey maps',
        date: '13th Sep 2023',
        time: '5 min read',
        tag: 'Developer'
    },
]
export const cryptoRoadMap = [
    {
        icon: BsPencil,
        month: 'January, 2023',
        title: 'Planning'
    },
    {
        icon: TbCoinBitcoin ,
        month: 'February, 2023',
        title: 'Blockchain Starts'
    },
    {
        icon: FiClipboard,
        month: 'March, 2023',
        title: 'Expanded Licensing'
    },
    {
        icon: PiMoneyBold,
        month: 'April, 2023',
        title: 'Token Sale'
    },
    {
        icon: FiAirplay,
        month: 'May, 2023',
        title: 'Project Launch'
    },
    {
        icon: AiOutlineEuro ,
        month: 'June, 2023',
        title: 'Tier 5 sold'
    },
    {
        icon: FiMonitor,
        month: 'December, 2023',
        title: 'Project Live'
    },
]
export const cryptoWorkData = [
    {
        icon: TbCoinBitcoin,
        title: 'Risk-Free Trade',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: MdOutlineLocalAtm ,
        title: 'Demo Account',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: FaRegCreditCard,
        title: 'Market',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: LuUser2,
        title: 'Personal Manager',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: FiClock,
        title: 'No Delays',
        desc: 'Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.'
    },
    {
        icon: PiMoneyBold,
        title: 'No Commission',
        desc: 'Composed in a pseudo-Latin language which more or less pseudo-Latin language corresponds.'
    },
]
export const aboutImage = [about1, about2, about3, about4, about8, about5, about6, about7]
export const startUpCounter = [
    {
        title:'Year of business',
        number: '9'
    },
    {
        title:'Number of Sales',
        number: '106'
    },
    {
        title:'Happy Customers',
        number: '93'
    },
]
export const servicesData = [
    {
        icon: PiHamburgerBold,
        title: 'Food Delivery',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: PiTreePalm,
        title: 'Grocery Delivery',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: PiPackage,
        title: ' Package Delivery',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: AiFillMedicineBox,
        title: 'Pharmacy Delivery',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: FiGift,
        title: 'Specialty Items Delivery',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: PiDog,
        title: 'Pet Supplies Delivery',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
]
export const teamData = [
    {
        image: client1,
        name: 'Calvin Carlo',
        title: 'Founder / C.E.O'
    },
    {
        image: client2,
        name: 'Janalia Joseph',
        title: 'Co-Founder'
    },
    {
        image: client3,
        name: 'Miriam Coll',
        title: 'C.O.O'
    },
    {
        image: client4,
        name: 'Cristino Murphy',
        title: 'Marketing'
    },
    {
        image: client5,
        name: 'David Ospina',
        title: 'Graphic Designer'
    },
    {
        image: client6,
        name: 'Amy Ford',
        title: 'Co-Founder'
    },
    {
        image: client7,
        name: 'Jane Mille',
        title: 'C.O.O'
    },
    {
        image: client8,
        name: 'Alex Tonmoy',
        title: 'Marketing'
    },
]
export const portfolioData = [
    {
        image: work1,
        title: 'Iphone mockup',
        desc: 'Branding',
        borderClass: 'card border-0 work-container m-3 work-primary work-border overflow-hidden rounded-md'
    },
    {
        image: work2,
        title: 'Iphone mockup',
        desc: 'Branding',
        borderClass: 'card border-0 work-container m-3 work-danger work-border overflow-hidden rounded-md'
    },
    {
        image: work3,
        title: 'Iphone mockup',
        desc: 'Branding',
        borderClass: 'card border-0 work-container m-3 work-info work-border overflow-hidden rounded-md'
    },
    {
        image: work4,
        title: 'Iphone mockup',
        desc: 'Branding',
        borderClass: 'card border-0 work-container m-3 work-success work-border overflow-hidden rounded-md'
    },
    {
        image: work5,
        title: 'Iphone mockup',
        desc: 'Branding',
        borderClass: 'card border-0 work-container m-3 work-warning work-border overflow-hidden rounded-md'
    },
    {
        image: work6,
        title: 'Iphone mockup',
        desc: 'Branding',
        borderClass: 'card border-0 work-container m-3 work-success work-border overflow-hidden rounded-md'
    },
    {
        image: work7,
        title: 'Iphone mockup',
        desc: 'Branding',
        borderClass: 'card border-0 work-container m-3 work-danger work-border overflow-hidden rounded-md'
    },
    {
        image: work8,
        title: 'Iphone mockup',
        desc: 'Branding',
        borderClass: 'card border-0 work-container m-3 work-info work-border overflow-hidden rounded-md'
    },
    {
        image: work9,
        title: 'Iphone mockup',
        desc: 'Branding',
        borderClass: 'card border-0 work-container m-3 work-warning work-border overflow-hidden rounded-md'
    },
]

export const expertiseData = [
    {
        icon: FiGlobe,
        title: 'Website Optimization',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: TbUsersGroup,
        title: 'Social Media Marketing',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: LuLink,
        title: 'Link Building Service',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: MdOutlinePayments,
        title: 'Pay Per Click Advertising',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: LuSearch,
        title: 'Search Engine Marketing',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: LuClipboardList,
        title: 'Content Marketing',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
]
export const seoBlog = [
    {
        image: seo1,
        title: 'Email Marketing',
        name: 'Branding',
    },
    {
        image: seo2,
        title: 'Content Marketing',
        name: 'Branding'
    },
    {
        image: seo3,
        title: 'Growth Marketing',
        name: 'Branding'
    },
    {
        image: seo4,
        title: 'SEO Marketing',
        name: 'Branding'
    },
    {
        image: seo5,
        title: 'Search Marketing',
        name: 'Branding'
    },
    {
        image: seo6,
        title: 'SMO Marketing',
        name: 'Branding'
    },
    {
        image: seo7,
        title: 'Digital Marketing',
        name: 'Branding'
    },
]
export const freelancerAbout = [
    {
        title:'WordPress',
        progress: '84%'
    },
    {
        title:'Angular / JavaScript',
        progress: '79%'
    },
    {
        title:'HTML',
        progress: '95%'
    },
]
export const freelancerOffer = [
    {
        icon: FiAirplay,
        title: 'UX / UI Design',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: FiSmartphone,
        title: 'Ios App Designer',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: FiAperture,
        title: 'Photography',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: RiImageAddFill,
        title: 'Graphic Designer',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: LuUser2,
        title: 'Customer Care',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: LuShieldCheck,
        title: 'Web Security',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
]
export const counterData = [
    {
        icon: FiSmile,
        target: '1251',
        title: 'Happy Client'
    },
    {
        icon: FiAward ,
        target: '15',
        title: 'Award Won'
    },
    {
        icon: FiCoffee,
        target: '3261',
        title: 'Cup of Coffee'
    },
    {
        icon: FiThumbsUp,
        target: '36',
        title: 'Project Complete'
    },
]
export const brokerFeature = [
    {
        icon: MdOutlinePayments,
        title: 'Forex'
    },
    {
        icon: FaUniversity,
        title: 'Stocks'
    },
    {
        icon: TbCoinBitcoin,
        title: 'Crypto'
    },
    {
        icon: AiFillGold ,
        title: 'Commodities'
    },
    {
        icon: FaRegBuilding,
        title: 'Real Estate'
    },
    {
        icon: BsShieldPlus,
        title: 'Insurance'
    },
]

export const developerPortfolio = [
    {
        image: work01,
        name:'Tachnology',
        title:'Landrick - Saas & Software Template',
        link:'https://1.envato.market/landrick',
    },
    {
        image: work02,
        name:'Retail',
        title:'Doctris - Appointment System',
        link:'https://1.envato.market/doctris-template',
    },
    {
        image: work03,
        name:'Personal',
        title:'Cristino - Personal Portfolio Template',
        link:'https://1.envato.market/cristino',
    },
    {
        image: work04,
        name:'Tachnology',
        title:'Appgen - Saas & Software Template',
        link:'https://1.envato.market/appgen-template',
    },
]
export const studioProject = [
    {
        image: work1,
        tag: 'Creative',
        name: 'Calvin',
        title: 'Creative and modern design'
    },
    {
        image: work2,
        tag: 'Creative',
        name: 'Calvin',
        title: 'Creative and modern design'
    },
    {
        image: work3,
        tag: 'Creative',
        name: 'Calvin',
        title: 'Creative and modern design'
    },
    {
        image: work4,
        tag: 'Creative',
        name: 'Calvin',
        title: 'Creative and modern design'
    },
]
export const clientImage = [ client1, client2, client3, client4, client5, client6, client7, client8 ]

export const hostingFeature = [
    {
        icon: VscServer,
        title: 'Web Hosting',
        desc: 'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon: TbCloudHeart,
        title: 'Domains',
        desc: 'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon: LuMailCheck,
        title: 'Emails',
        desc: 'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon: LuUsers2,
        title: 'Support',
        desc: 'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon: LiaTachometerAltSolid ,
        title: 'Speedy',
        desc: 'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon: FaRegDotCircle,
        title: 'Reliable',
        desc: 'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon: LiaWeightSolid,
        title: 'Scalable',
        desc: 'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon: LiaFileInvoiceDollarSolid,
        title: 'Easy Upgrade',
        desc: 'There are many variations of passages of Lorem Ipsum available'
    },
]

export const domainPlan = [
    {
        name: '.in',
        amount: '$4.99'
    },
    {
        name: '.com',
        amount: '$5.99'
    },
    {
        name: '.org',
        amount: '$6.99'
    },
    {
        name: '.net',
        amount: '$7.99'
    },
    {
        name: '.info',
        amount: '$3.99'
    },
    {
        name: '.me',
        amount: '$2.99'
    },
]
export const pricingThree = [
    {
        title: 'Basic',
        amount: '0',
        btn:'Free Singup',
        band: '1GB',
        space: '50MB',
        support: 'No'
    },
    {
        title: 'Business',
        amount: '39',
        btn:'Join Now',
        band: '100GB',
        space: '500MB',
        support: 'Yes'
    },
    {
        title: 'Premium',
        amount: '59',
        btn:'Buy Now',
        band: '500GB',
        space: '2GB',
        support: 'Yes'
    }
]

export const aboutData = [
    {
        icon: TbChartPie4,
        title: 'Our Vision',
        desc: 'At Operation Track, our vision is to revolutionize the delivery experience, transforming it into a seamless, sustainable, and community-driven ecosystem that enriches lives and empowers businesses.We envision a future where getting what you need, when you need it, is effortless and sustainable. Our delivery app will serve as the bridge connecting customers with local businesses, offering a diverse range of products and services at their fingertips.'
    },
    {
        icon: RiCrosshair2Fill,
        title: 'Our Goals',
        desc: 'Ensure that customers are consistently satisfied with the delivery experience, including timely deliveries, accurate orders, and responsive customer support.'
    },
    {
        icon: FiAirplay,
        title: 'Our Mission',
        desc: 'Our mission is simple: to make your life easier by providing seamless delivery solutions that save you time and effort. We understand the importance of convenience in todays fast-paced world, and we are committed to bringing you the best delivery experience possible.'
    },
]

export const sponsorsData = [
    {
        image: sponsor1,
        title: 'Airbnb'
    },
    {
        image: sponsor2,
        title: 'Apple'
    },
    {
        image: sponsor3,
        title: 'Bayer'
    },
    {
        image: sponsor4,
        title: 'Black Berry'
    },
    {
        image: sponsor5,
        title: 'Boomerang'
    },
    {
        image: sponsor6,
        title: 'Volkswagen'
    },
    {
        image: sponsor7,
        title: 'Box'
    },
    {
        image: sponsor8,
        title: 'Layout'
    },
    {
        image: sponsor9,
        title: 'Pingdom'
    },
    {
        image: sponsor10,
        title: 'Swiggy'
    },
]
export const accordionData = [
    {
        id:1,
        title:'How does Operation Track work? ',
        desc:'Operation Track is a convenient delivery app that connects you with local businesses and delivery partners. Simply download the app, browse through the available options, place your order, and track your delivery in real-time.'
    },
    {
        id:2,
        title:'How do I track my delivery?',
        desc:'Once your order is placed, you can track your delivery in real-time through the app. You will receive updates on the status of your order and the estimated time of arrival.'
    },
    {
        id:3,
        title:'What are the delivery fees?',
        desc:'Delivery fees may vary depending on the distance, time of day, and other factors. The app will display the delivery fee before you place your order.'
    },
    {
        id:4,
        title:'WWhat if I have dietary restrictions or special requests?',
        desc:'You can specify any dietary restrictions or special requests in the order notes section during checkout. Our delivery partners will do their best to accommodate your preferences.'
    },
]
export const faqAbout = [
    {
        icon: SlBookOpen, 
        title: 'Get started',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon:LiaFileInvoiceDollarSolid, 
        title: 'Pricing plan',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
    {
        icon: FiThumbsUp, 
        title: 'Sales questions',
        desc: 'Real-time tracking of food orders from preparation to delivery'
    },
]
export const connectionsData = [
    {
        image: client2,
        title: 'Garner'
    },
    {
        image: client3,
        title: 'Barbara'
    },
    {
        image: client4,
        title: 'Thomas'
    },
    {
        image: client5,
        title: 'Wanda'
    },
    {
        image: client6,
        title: 'Russell'
    },
    {
        image: client7,
        title: 'Douglas'
    },
    {
        image: client8,
        title: 'Emerson'
    },
]

export const educationData = [
    {
       name: 'Studied At Cambridge Uni.',
       year: '2008 - 2012',
       location: 'London, UK',
       class: 'd-flex features feature-primary align-items-center mt-4 p-3 rounded shadow' 
    },
    {
        name: 'Master In Computer Science',
        year: '2012 - 2014',
        location: 'Washington D.C, USA',
        class: 'd-flex features feature-danger align-items-center mt-4 p-3 rounded shadow' 
     },
     {
        name: 'UI/UX Designer',
        year: '2014 - 2016',
        location: 'Perth, Australia',
        class: 'd-flex features feature-secondary align-items-center mt-4 p-3 rounded shadow' 
     },
]

export const experienceData = [
    {
        image: sponsor7,
        title: 'Senior Web Developer',
        year: '3 Years Experience',
        name:'Airbnb',
        location:'@London, UK'
    },
    {
        image: sponsor2,
        title: 'Web Designer',
        year: '2 Years Experience',
        name:'Apple',
        location:'@Washington, USA'
    },
    {
        image: sponsor3,
        title: 'UI Designer',
        year: '2 Years Experience',
        name:'Swiggy',
        location:'@Perth, Australia'
    },
]
export const portfolio = [
    {
        id:1,
        image:work1,
        name:'Web',
        title:'Web Developing',
        category:'branding'
    },
    {
        id:2,
        image:work2,
        name:'Web',
        title:'Web Developing',
        category:'designing'
    },
    {
        id:3,
        image:work3,
        name:'Web',
        title:'Web Developing',
        category:'photography'
    },
    {
        id:4,
        image:work4,
        name:'Web',
        title:'Web Developing',
        category:'development'
    },
    {
        id:5,
        image:work5,
        name:'Web',
        title:'Web Developing',
        category:'branding'
    },
    {
        id:6,
        image:work6,
        name:'Web',
        title:'Web Developing',
        category:'photography'
    },
    {
        id:7,
        image:work7,
        name:'Web',
        title:'Web Developing',
        category:'development'
    },
    {
        id:8,
        image:work8,
        name:'Web',
        title:'Web Developing',
        category:'branding'
    },
    {
        id:9,
        image:work9,
        name:'Web',
        title:'Web Developing',
        category:'designing'
    },
    {
        id:10,
        image:work10,
        name:'Web',
        title:'Web Developing',
        category:'photography'
    },
    {
        id:11,
        image:work11,
        name:'Web',
        title:'Web Developing',
        category:'development'
    },
    {
        id:12,
        image:work12,
        name:'Web',
        title:'Web Developing',
        category:'branding'
    },
]
export const portfolioImage = [work1, work2, work3, work4, work5, work6, work7, work8, work9, work10, work11, work12]
export const mesonaryPortfolio = [
    {
        id:1,
        image:work1,
        name:'Web',
        title:'Web Developing',
        category:'branding'
    },
    {
        id:2,
        image:work13,
        name:'Web',
        title:'Web Developing',
        category:'designing'
    },
    {
        id:3,
        image:work2,
        name:'Web',
        title:'Web Developing',
        category:'photography'
    },
    {
        id:4,
        image:work14,
        name:'Web',
        title:'Web Developing',
        category:'development'
    },
    {
        id:5,
        image:work3,
        name:'Web',
        title:'Web Developing',
        category:'branding'
    },
    {
        id:6,
        image:work15,
        name:'Web',
        title:'Web Developing',
        category:'photography'
    },
    {
        id:7,
        image:work4,
        name:'Web',
        title:'Web Developing',
        category:'development'
    },
    {
        id:8,
        image:work16,
        name:'Web',
        title:'Web Developing',
        category:'branding'
    },
    {
        id:9,
        image:work5,
        name:'Web',
        title:'Web Developing',
        category:'designing'
    },
    {
        id:10,
        image:work17,
        name:'Web',
        title:'Web Developing',
        category:'photography'
    },
    {
        id:11,
        image:work6,
        name:'Web',
        title:'Web Developing',
        category:'development'
    },
    {
        id:12,
        image:work7,
        name:'Web',
        title:'Web Developing',
        category:'branding'
    },
]
export const mesonaryImage = [work1, work13, work2, work14, work3, work15, work4, work16, work5, work17, work6, work7]