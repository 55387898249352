import React, { useState } from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-lines-one.png'

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import { FiArrowRight } from '../../assets/icons/icons'
import { accordionData } from "../../data/data";

export default function PageTerms(){
    let [activeIndex, setActiveIndex] = useState(1)
    return(
        <>
        <Navbar/>
        <section className="bg-half-130 d-table bg-light w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'center'}}>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="heading-title text-center">
                            <h3 className="title mb-3">Terms of Services</h3>
                            <p className="para-desc mx-auto text-muted mb-0">Explore and learn more about everything from machine learning and global payments to  scaling your team.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="card shadow border-0 rounded">
                            <div className="card-body">
                                <h5 className="card-title">Introduction :</h5>
                                <p className="text-muted">It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.</p>

                                <h5 className="card-title">User Agreements :</h5>
                                <p className="text-muted">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words. This ancient dummy text is also incomprehensible, but it imitates the rhythm of most European languages in Latin script. The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from the layout.</p>
                                <p className="text-muted">There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories.</p>
                                <p className="text-muted">It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.</p>
                                
                                <h5 className="card-title">Restrictions :</h5>
                                <p className="text-muted">You are specifically restricted from all of the following :</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="ms-0"><FiArrowRight className="fea icon-sm me-2"/>Digital Marketing Solutions for Tomorrow</li>
                                    <li className="ms-0"><FiArrowRight className="fea icon-sm me-2"/>Our Talented & Experienced Marketing Agency</li>
                                    <li className="ms-0"><FiArrowRight className="fea icon-sm me-2"/>Create your own skin to match your brand</li>
                                    <li className="ms-0"><FiArrowRight className="fea icon-sm me-2"/>Digital Marketing Solutions for Tomorrow</li>
                                    <li className="ms-0"><FiArrowRight className="fea icon-sm me-2"/>Our Talented & Experienced Marketing Agency</li>
                                    <li className="ms-0"><FiArrowRight className="fea icon-sm me-2"/>Create your own skin to match your brand</li>
                                </ul>

                                <h5 className="card-title">Users Question & Answer :</h5>
                                <div className="accordion mt-4">
                                    {accordionData.map((item,index) =>{
                                        return(
                                            <div className="accordion-item rounded mt-2" key={index}>
                                                <h2 className="accordion-header">
                                                    <button className={`${activeIndex === item.id ? '' : 'collapsed'} accordion-button border-0 bg-light`}type="button" onClick={()=>setActiveIndex(item.id)}>
                                                        {item.title}
                                                    </button>
                                                </h2>
                                                <div className={`${activeIndex === item.id ? 'show' : ' '} accordion-collapse border-0 collapse`}>
                                                    <div className="accordion-body text-muted">
                                                        {item.desc}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="mt-3">
                                    <Link to="#" className="btn btn-primary mt-2 me-2">Accept</Link>
                                    <Link to="#" className="btn btn-soft-primary mt-2">Decline</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}