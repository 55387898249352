import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/bg-lines-one.png'
import payment1 from '../../assets/images/payment/visa.png'
import payment2 from '../../assets/images/payment/mastercard.png'
import payment3 from '../../assets/images/payment/american.png'

import Navbar from "../../components/navbar";
import Footer from "../../components/footer"
import ScrollTop from "../../components/scrollTop"

import { FaEllipsisV, FiPlus, FiSettings, FiTrash2 } from '../../assets/icons/icons'

export default function ProfilePayment(){
    let [ show, setShow ] = useState('');

    useEffect(() =>{
        let closeModal =()=>{
            setShow('')
        }
        document.addEventListener('mousedown', closeModal);

        return()=>{
            document.removeEventListener('mousedown', closeModal);
        }
    })
    return(
        <>
        <Navbar/>

        <section className="bg-half-130 d-table bg-light w-100" style={{backgroundImage:`url(${bg1})` , backgroundPosition:'center'}}>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="heading-title text-center">
                            <h3 className="title mb-2">Payments</h3>
                            <h5 className="text-muted mb-0">@calvin_carlo</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="rounded shadow">
                            <div className="p-4 d-flex align-items-center justify-content-between">
                                <h5 className="mb-0">Payment Method</h5>

                                <Link to="#" className="btn btn-primary"><FiPlus /> Add</Link>
                            </div>
                            <div className="p-4 border-top">
                                <div className="pb-4">
                                    <h6>Calvin Carlo <span className="badge rounded-pill bg-soft-success ms-2">Default</span></h6>

                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center mt-1">
                                            <img src={payment1} className="avatar avatar-small" alt=""/>
                                            <div className="flex-1 ms-3">
                                                <h6 className="mb-0">Visa &nbsp;&nbsp; •••• 4559</h6>
                                                <p className="text-muted mb-0">Expires 12/22</p>
                                            </div>
                                        </div>

                                        <div className="btn-group dropdown dropdown-primary">
                                            <button type="button" className="btn btn-icon btn-pills btn-soft-primary dropdown-toggle"><FaEllipsisV className="icons" onClick={() => setShow(1)}/></button>
                                            <div className={`${show === 1 ? 'show' : ''} dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-2 py-3`} style={{width: '200px', right:'0', top:'100%'}}>
                                                <Link className="dropdown-item text-dark d-inline-flex align-items-center" to="#"><FiSettings className="align-middle me-1"/> Edit</Link>
                                                <Link className="dropdown-item text-dark d-inline-flex align-items-center" to="#"><FiTrash2 className="align-middle me-1"/>Delete</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-4 border-top">
                                    <h6>Calvin Carlo</h6>

                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center mt-1">
                                            <img src={payment2} className="avatar avatar-small" alt=""/>
                                            <div className="flex-1 ms-3">
                                                <h6 className="mb-0">Mastercard &nbsp;&nbsp; •••• 4584</h6>
                                                <p className="text-muted mb-0">Expires 12/22</p>
                                            </div>
                                        </div>

                                        <div className="btn-group dropdown dropdown-primary">
                                            <button type="button" className="btn btn-icon btn-pills btn-soft-primary dropdown-toggle" onClick={() => setShow(2)}><FaEllipsisV className="icons"/></button>
                                            <div className={`${show === 2 ? 'show' : ''} dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-2 py-3`} style={{width: '200px', right:'0', top:'100%'}} >
                                                <Link className="dropdown-item text-dark d-inline-flex align-items-center" to="#"><FiSettings className="align-middle me-1"/> Edit</Link>
                                                <Link className="dropdown-item text-dark d-inline-flex align-items-center" to="#"><FiTrash2 className="align-middle me-1"/>Delete</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-4 border-top">
                                    <h6>Calvin Carlo <span className="badge rounded-pill bg-soft-danger ms-2">Expired</span></h6>

                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center mt-1">
                                            <img src={payment3} className="avatar avatar-small" alt=""/>
                                            <div className="flex-1 ms-3">
                                                <h6 className="mb-0">American &nbsp;&nbsp; •••• 5796</h6>
                                                <p className="text-muted mb-0">Expires 12/22</p>
                                            </div>
                                        </div>

                                        <div className="btn-group dropdown dropdown-primary">
                                            <button type="button" className="btn btn-icon btn-pills btn-soft-primary dropdown-toggle" onClick={() => setShow(3)}><FaEllipsisV className="icons"/></button>
                                            <div className={`${show === 3 ? 'show' : ''} dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-2 py-3`} style={{width: '200px', right:'0', top:'100%'}}>
                                                <Link className="dropdown-item text-dark d-inline-flex align-items-center" to="#"><FiSettings className="align-middle me-1"/> Edit</Link>
                                                <Link className="dropdown-item text-dark d-inline-flex align-items-center" to="#"><FiTrash2 className="align-middle me-1"/>Delete</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rounded shadow mt-4">
                            <div className="p-4">
                                <h5 className="mb-0">Payment History</h5>
                            </div>

                            <div className="p-4 border-top">
                                <ul className="list-unstyled d-md-flex justify-content-between">
                                    <li className="list-inline-item">
                                        <ul className="list-unstyled mb-0">
                                            <li className="list-inline-item me-3"><Link to="#" className="text-primary">20th Sep. 2020</Link></li>
                                            <li className="list-inline-item h6 mb-0">Landflow Landing</li>
                                        </ul>
                                    </li>

                                    <li className="list-inline-item h6">$ 16.00</li>
                                </ul>

                                <ul className="list-unstyled d-md-flex justify-content-between">
                                    <li className="list-inline-item">
                                        <ul className="list-unstyled mb-0">
                                            <li className="list-inline-item me-3"><Link to="#" className="text-primary">16th Sep. 2020</Link></li>
                                            <li className="list-inline-item h6 mb-0">Landrick Landing</li>
                                        </ul>
                                    </li>

                                    <li className="list-inline-item h6">$ 16.00</li>
                                </ul>

                                <ul className="list-unstyled d-md-flex justify-content-between">
                                    <li className="list-inline-item">
                                        <ul className="list-unstyled mb-0">
                                            <li className="list-inline-item me-3"><Link to="#" className="text-primary">1st Sep. 2020</Link></li>
                                            <li className="list-inline-item h6 mb-0">Hostick Landing</li>
                                        </ul>
                                    </li>

                                    <li className="list-inline-item h6">$ 16.00</li>
                                </ul>

                                <ul className="list-unstyled mb-0 d-md-flex justify-content-between">
                                    <li className="list-inline-item">
                                        <ul className="list-unstyled mb-0">
                                            <li className="list-inline-item me-3"><Link to="#" className="text-primary">25th Aug. 2020</Link></li>
                                            <li className="list-inline-item h6 mb-0">Cristino Personal</li>
                                        </ul>
                                    </li>

                                    <li className="list-inline-item h6">$ 16.00</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}